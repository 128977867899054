export function fixNaNSorting(){
    /* Define a sort function that keeps empty rank cells at the bottom and not the top */
    jQuery.fn.dataTableExt.oSort['numWithNull-asc'] = function(a,b) {
        const x = parseInt(a);
        const y = parseInt(b);
        return ((isNaN(y) || x < y) ? -1 : ((isNaN(x) || x > y) ? 1 : 0));
    };
    jQuery.fn.dataTableExt.oSort['numWithNull-desc'] = function(a,b) {
        const x = parseInt(a);
        const y = parseInt(b);
        return ((isNaN(y) || x < y) ? 1 : ((isNaN(x) || x > y) ? -1 : 0));
    };
}

/** audio player */
export function htmlAudioPlayer ( wav ) {
    const base_url = `${window.configurations.leaderboard_archive}/challenges/audio_samples/`;
    return '<button type="button" class="btn btn-default" ' +
        'onclick="window.commons.audio.toggle_play(this)">' +
        '<span><i class="fa fa-play pointer"></i></span>' +
        '<audio src="'+ base_url + wav + '"' + ' preload="none"> </audio>'+
        '</button>';
}

/** custom sort function for cells with % & NaN values */
export function sort_score(cell){
    let score = cell.split('<br/>').reduce((p, c) => p + parseFloat(c.replaceAll('%', '')), 0);
    return isNaN(score) ? Number.NEGATIVE_INFINITY:score
}

/** custom sorting function for dual-row items (sort by first row without '%' char) */
export function dualRowSortFunction(){
    $.fn.dataTableExt.oSort["dualrow-asc"] = (x, y) =>  sort_score(x) > sort_score(y) ? 1:-1
    $.fn.dataTableExt.oSort["dualrow-desc"] = (x, y) =>  sort_score(x) > sort_score(y) ? -1:1
}

/** string parsing function */
export function _(item, options = {}) {
    const fixed = options.fixed || 2;
    const no_decimal = options.no_decimal || false;
    const to_percent = options.to_percent || false;
    const empty_value = options.empty_value || "-";


    if(item == null)
        return empty_value;

    if((typeof item) == "number") {
        // return as percentage
        if(to_percent)
            return `${(item * 100).toFixed(fixed)}%`

        // remove decimal part
        if (no_decimal)
            return item.toFixed(0);

        // return with fixed precision
        return item.toFixed(fixed);
    }

    // no formatting
    return item;
}

/** converts a cell to percentage */
export function to_percent(item){
    if(item == null){
        return "-"
    }
    if((typeof item) == "number") {
        return `${(item * 100).toFixed(2)}%`
    }
    return item;
}

/** Extract a value from a lamda or return default
 * used mostly for safe access of embedded values in dictionaries
 * @param fn
 * @param defaultVal
 * @returns {null|*}
 */
export function get(fn, defaultVal=null){
    try {
        const res = fn();
        if(res) return res;
        return defaultVal;
    } catch (e) {
        return defaultVal;
    }
}
