/** audio player */
export function audio_sample ( wav ) {
    const base_url = `${window.configurations.leaderboard_archive}/challenges/audio_samples/`;
    return '<button type="button" class="btn btn-default" ' +
        'onclick="window.commons.audio.toggle_play(this)">' +
        '<span><i class="fa fa-play pointer"></i></span>' +
        '<audio src="'+ base_url + wav + '"' + ' preload="none"> </audio>'+
        '</button>';
}


/* Formatting function for row details - modify as you need */
export function format ( d ) {
    let date = d.metadata.submitted_at.replace("T", " ").slice(0, -6);

    // `d` is the original data object for the row
    return '<h4>General information</h4>'+
        '<div style="float:left; width:50%;">'+
        '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">'+
        '<tr>'+
        '<td>Authors:</td>'+
        '<td>'+d.metadata.author+'</td>'+
        '</tr>'+
        '<tr>'+
        '<td>Affiliation:</td>'+
        '<td>'+d.metadata.affiliation+'</td>'+
        '</tr>'+
        '<tr>'+
        '<td>Submission date:</td>'+
        '<td>'+date+'</td>'+
        '</tr>'+
        '</table>'+
        '</div>'+
        '<div style="float:right; width:50%;">'+
        '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">'+
        '<tr>'+
        '<td>Is open science?</td>'+
        '<td>'+d.metadata.open_source+'</td>'+
        '</tr>'+
        '<tr>'+
        '<td>Using external data?</td>'+
        '<td>'+d.metadata.using_external_data+'</td>'+
        '</tr>'+
        '<tr>'+
        '<td>Using parallel train?</td>'+
        '<td>'+d.metadata.using_parallel_train+'</td>'+
        '</tr>'+
        '</table>'+
        '</div>'+
        '<div style="float:right; width:100%;">'+
        '<br>' +
        '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">'+
        '<tr>'+
        '<td>System description:</td>'+
        '<td>'+d.metadata.system_description+'</td>'+
        '</tr>'+
        '<tr>'+
        '<td>Auxiliary embedding 1 description:</td>'+
        '<td>'+d.metadata.auxiliary1_description+'</td>'+
        '</tr>'+
        '<tr>'+
        '<td>Auxiliary embedding 2 description:</td>'+
        '<td>'+d.metadata.auxiliary2_description+'</td>'+
        '</tr>'+
        '</table>' +
        '</div>'+

        '<div style="float:right; width:100%;">'+
        '<h4>Detailed ABX</h4>' +

        '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">'+
        '<thead>' +
        '<tr>' +
        '<th rowspan="2"></th><th  class="dt-center" colspan="3">Surprise</th>' +
        '<th  class="dt-center" colspan="3">English</th>' +
        '</tr>' +
        '<tr>' +
        '<th class="dt-center">Test</th><th class="dt-center">Auxiliary 1</th>' +
        '<th class="dt-center">Auxiliary 2</th>' +
        '<th class="dt-center">Test</th><th class="dt-center">Auxiliary 1</th>' +
        '<th class="dt-center">Auxiliary 2</th>' +
        '</tr>' +
        '</thead>' +
        '<tbody>'+
        '<tr>'+
        '<th>DTW cosine</th>'+
        '<td class="dt-center">' + d.details_abx.surprise_test_abx_dtw_cosine+ '</td>'+
        '<td class="dt-center">' + d.details_abx.surprise_auxiliary1_abx_dtw_cosine+ '</td>'+
        '<td class="dt-center">' + d.details_abx.surprise_auxiliary2_abx_dtw_cosine+ '</td>'+
        '<td class="dt-center">' + d.details_abx.english_test_abx_dtw_cosine+ '</td>'+
        '<td class="dt-center">' + d.details_abx.english_auxiliary1_abx_dtw_cosine+ '</td>'+
        '<td class="dt-center">' + d.details_abx.english_auxiliary2_abx_dtw_cosine+ '</td>'+
        '</tr>'+
        '<tr>'+
        '<th>DTW KL</th>'+
        '<td class="dt-center">' + d.details_abx.surprise_test_abx_dtw_kl+ '</td>'+
        '<td class="dt-center">' + d.details_abx.surprise_auxiliary1_abx_dtw_kl+ '</td>'+
        '<td class="dt-center">' + d.details_abx.surprise_auxiliary2_abx_dtw_kl+ '</td>'+
        '<td class="dt-center">' + d.details_abx.english_test_abx_dtw_kl+ '</td>'+
        '<td class="dt-center">' + d.details_abx.english_auxiliary1_abx_dtw_kl+ '</td>'+
        '<td class="dt-center">' + d.details_abx.english_auxiliary2_abx_dtw_kl+ '</td>'+
        '</tr>'+
        '<tr>'+
        '<th>Leveinshtein</th>'+
        '<td class="dt-center">' + d.details_abx.surprise_test_abx_levenshtein+ '</td>'+
        '<td class="dt-center">' + d.details_abx.surprise_auxiliary1_abx_levenshtein+ '</td>'+
        '<td class="dt-center">' + d.details_abx.surprise_auxiliary2_abx_levenshtein+ '</td>'+
        '<td class="dt-center">' + d.details_abx.english_test_abx_levenshtein+ '</td>'+
        '<td class="dt-center">' + d.details_abx.english_auxiliary1_abx_levenshtein+ '</td>'+
        '<td class="dt-center">' + d.details_abx.english_auxiliary2_abx_levenshtein+ '</td>'+
        '</tr>'+
        '</tbody>' +
        '</table>' +

        '<h4>Detailed bitrate</h4>' +
        '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">'+
        '<thead>' +
        '<tr>' +
        '<th></th> <th class="dt-center">Test</th> <th>Auxiliary 1</th> <th>Auxiliary 2</th>' +
        '</tr>' +
        '</thead>' +
        '<tbody>'+
        '<tr>'+
        '<th>Surprise</th>' +
        '<td class="dt-center">' + d.details_bitrate.surprise_test_bitrate + '</td>'+
        '<td class="dt-center">' + d.details_bitrate.surprise_auxiliary1_bitrate + '</td>'+
        '<td class="dt-center">' + d.details_bitrate.surprise_auxiliary2_bitrate + '</td>'+
        '</tr>'+
        '<tr>'+
        '<th>English</th>' +
        '<td class="dt-center">' + d.details_bitrate.english_test_bitrate + '</td>'+
        '<td class="dt-center">' + d.details_bitrate.english_auxiliary1_bitrate + '</td>'+
        '<td class="dt-center">' + d.details_bitrate.english_auxiliary2_bitrate + '</td>'+
        '</tr>'+
        '</tbody>' +
        '</table>' +

        '<h4>Speech synthesis samples</h4>' +
        '<table cellpadding="5" cellspacing="5" border="0" style="padding-left:50px;">'+
        '<thead>' +
        '<tr>' +
        '<th>Language</th> <th>Utterance</th> <th> Source </th> <th> Target </th> <th>Synthesis</th>' +
        '</tr>' +
        '</thead>' +
        '<tbody>'+
        '<tr>' +
        '<th rowspan="2">Surprise</th>' +
        '<th class="dt-center">117311</th>' +
        '<td class="dt-center">' + audio_sample('surprise_1_source.wav') + '</td>'+
        '<td class="dt-center">' + audio_sample('surprise_1_target.wav') + '</td>'+
        '<td class="dt-center">' + audio_sample(d.audio_samples.surprise_1) + '</td>'+
        '</tr>' +
        '<tr>' +
        '<th class="dt-center">117301</th>' +
        '<td class="dt-center">' + audio_sample('surprise_2_source.wav') + '</td>'+
        '<td class="dt-center">' + audio_sample('surprise_2_target.wav') + '</td>'+
        '<td class="dt-center">' + audio_sample(d.audio_samples.surprise_2) + '</td>'+
        '</tr>' +
        '<tr>' +
        '<th rowspan="2">English</th>' +
        '<th class="dt-center">1478639117</th>' +
        '<td class="dt-center">' + audio_sample('english_1_source.wav') + '</td>'+
        '<td class="dt-center">' + audio_sample('english_1_target.wav') + '</td>'+
        '<td class="dt-center">' + audio_sample(d.audio_samples.english_1) + '</td>'+
        '</tr>' +
        '<tr>' +
        '<th class="dt-center">2436309351</th>' +
        '<td class="dt-center">' + audio_sample('english_2_source.wav') + '</td>'+
        '<td class="dt-center">' + audio_sample('english_2_target.wav') + '</td>'+
        '<td class="dt-center">' + audio_sample(d.audio_samples.english_2) + '</td>'+
        '</tr>' +
        '</tbody>' +
        '</table>' +
        '<br>' +
        '</div>'
}