import {format_track1_2015, format_track2_2015} from "./challenges/zr2015.js"
import {format_track2_2017} from "./challenges/zr2017.js"
import {format as details_2019} from './challenges/zr2019.js'
import {format_19, format_2017t2} from './challenges/zr2020.js'
import {getDetailView as format_2021} from './challenges/zr2021.js'
import {getDetailView as genericView} from './generic.js'
import {tdeDetailedResults} from './tde.js'
import {tts019DetailedResults} from "./tts019.js";
import {sLM21DetailedResults} from "./slm21.js";

/** index of all detailed view builders */
export const detailed_views = {
    challenges: {
        zr2015: {
            track1: format_track1_2015,
            track2: format_track2_2015,
        },
        zr2017: {
            track1_across: null,
            track1_within: null,
            track2: format_track2_2017
        },
        zr2019: details_2019,
        zr2020: {
            track1: format_19,
            track3: format_2017t2
        },
        zr2021: format_2021
    },
    abx15: (data) => genericView(data, []),
    abx17: (data) => genericView(data, []),
    abxLS: (data) => genericView(data, []),
    abxLS_v2: (data) => genericView(data, []),
    tde15: (data) => genericView(data, [tdeDetailedResults]),
    tde17: (data) => genericView(data, [tdeDetailedResults]),
    tts019: (data) => genericView(data, [tts019DetailedResults]),
    sLM21: (data) => genericView(data, [sLM21DetailedResults]),
    sLM21_prosody: (data) => genericView(data, []),
}