
/* Formatting function for row details - in zr2017 track2 */
export function format_track2_2017 ( d ) {
    //var date = d.metadata.submitted_at.replace("T", " ").slice(0, -6);

    // `d` is the original data object for the row
    return '<h4>General information</h4>'+
        '<div style="float:left; width:50%;">'+
        '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">'+
        '<tr>'+
        '<td>Authors:</td>'+
        '<td>'+d.metadata.author+'</td>'+
        '</tr>'+
        '<tr>'+
        '<td>DOI:</td>'+
        '<td>'+d.metadata.DOI+'</td>'+
        '</tr>'+
        '</table>'+
        '</div>'+
        '<div style="float:right; width:100%;">'+
        '<h4>Detailed Results</h4>'+
        '<table cellpadding="2" cellspacing="0" border="0" style="padding-left:50px;">'+
        '<thead>' +
        '<tr>' +
        '<th rowspan="2"></th><th  class="dt-center" colspan="3">Grouping</th>' +
        '</th><th  class="dt-center" colspan="3">Token</th>' +
        '</th><th  class="dt-center" colspan="3">Type</th>' +
        '</th><th  class="dt-center" colspan="3">Boundary</th>' +
        '</th><th  class="dt-center" colspan="4">NLP</th>' +
        '</tr>' +
        '<tr>' +
        '<th class="dt-center">Precision</th><th class="dt-center">Recall</th>'+
        '<th class="dt-center">Fscore</th>' +
        '<th class="dt-center">Precision</th><th class="dt-center">Recall</th>'+
        '<th class="dt-center">Fscore</th>' +
        '<th class="dt-center">Precision</th><th class="dt-center">Recall</th>'+
        '<th class="dt-center">Fscore</th>' +
        '<th class="dt-center">Precision</th><th class="dt-center">Recall</th>'+
        '<th class="dt-center">Fscore</th>' +
        '<th class="dt-center">NED</th><th class="dt-center">Coverage</th>'+
        '<th class="dt-center">n-words</th><th class="dt-center">n-pairs</th>' +

        '</tr>' +
        '</thead>' +
        '<tbody>'+
        '<tr>'+
        '<th>English</th>'+
        '<td class="lborder dt-center">' + d.scores.grouping_precision_english+ '</td>'+
        '<td class="dt-center">' + d.scores.grouping_recall_english+ '</td>'+
        '<td class="dt-center">' + d.scores.grouping_fscore_english+ '</td>'+
        '<td class="lborder dt-center">' + d.scores.token_precision_english+ '</td>'+
        '<td class="dt-center">' + d.scores.token_recall_english+ '</td>'+
        '<td class="dt-center">' + d.scores.token_fscore_english+ '</td>'+
        '<td class="lborder dt-center">' + d.scores.type_precision_english+ '</td>'+
        '<td class="dt-center">' + d.scores.type_recall_english+ '</td>'+
        '<td class="dt-center">' + d.scores.type_fscore_english+ '</td>'+
        '<td class="lborder dt-center">' + d.scores.boundary_precision_english+ '</td>'+
        '<td class="dt-center">' + d.scores.boundary_recall_english+ '</td>'+
        '<td class="dt-center">' + d.scores.boundary_fscore_english+ '</td>'+
        '<td class="lborder dt-center">' + d.scores.ned_english+ '</td>'+
        '<td class="dt-center">' + d.scores.coverage_english+ '</td>'+
        '<td class="dt-center">' + d.scores.n_words_english+ '</td>'+
        '<td class="dt-center">' + d.scores.n_pairs_english+ '</td>'+

        '</tr>'+
        '<tr>'+
        '<th>French</th>'+
        '<td class="lborder dt-center">' + d.scores.grouping_precision_french+ '</td>'+
        '<td class="dt-center">' + d.scores.grouping_recall_french+ '</td>'+
        '<td class="dt-center">' + d.scores.grouping_fscore_french+ '</td>'+
        '<td class="lborder dt-center">' + d.scores.token_precision_french+ '</td>'+
        '<td class="dt-center">' + d.scores.token_recall_french+ '</td>'+
        '<td class="dt-center">' + d.scores.token_fscore_french+ '</td>'+
        '<td class="lborder dt-center">' + d.scores.type_precision_french+ '</td>'+
        '<td class="dt-center">' + d.scores.type_recall_french+ '</td>'+
        '<td class="dt-center">' + d.scores.type_fscore_french+ '</td>'+
        '<td class="lborder dt-center">' + d.scores.boundary_precision_french+ '</td>'+
        '<td class="dt-center">' + d.scores.boundary_recall_french+ '</td>'+
        '<td class="dt-center">' + d.scores.boundary_fscore_french+ '</td>'+
        '<td class="lborder dt-center">' + d.scores.ned_french+ '</td>'+
        '<td class="dt-center">' + d.scores.coverage_french+ '</td>'+
        '<td class="dt-center">' + d.scores.n_words_french+ '</td>'+
        '<td class="dt-center">' + d.scores.n_pairs_french+ '</td>'+

        '</tr>'+

        '<tr>'+
        '<th>Mandarin</th>'+
        '<td class="lborder dt-center">' + d.scores.grouping_precision_mandarin+ '</td>'+
        '<td class="dt-center">' + d.scores.grouping_recall_mandarin+ '</td>'+
        '<td class="dt-center">' + d.scores.grouping_fscore_mandarin+ '</td>'+
        '<td class="lborder dt-center">' + d.scores.token_precision_mandarin+ '</td>'+
        '<td class="dt-center">' + d.scores.token_recall_mandarin+ '</td>'+
        '<td class="dt-center">' + d.scores.token_fscore_mandarin+ '</td>'+
        '<td class="lborder dt-center">' + d.scores.type_precision_mandarin+ '</td>'+
        '<td class="dt-center">' + d.scores.type_recall_mandarin+ '</td>'+
        '<td class="dt-center">' + d.scores.type_fscore_mandarin+ '</td>'+
        '<td class="lborder dt-center">' + d.scores.boundary_precision_mandarin+ '</td>'+
        '<td class="dt-center">' + d.scores.boundary_recall_mandarin+ '</td>'+
        '<td class="dt-center">' + d.scores.boundary_fscore_mandarin+ '</td>'+
        '<td class="lborder dt-center">' + d.scores.ned_mandarin+ '</td>'+
        '<td class="dt-center">' + d.scores.coverage_mandarin+ '</td>'+
        '<td class="dt-center">' + d.scores.n_words_mandarin+ '</td>'+
        '<td class="dt-center">' + d.scores.n_pairs_mandarin+ '</td>'+

        '</tr>'+

        '<tr>'+
        '<th>LANG1</th>'+
        '<td class="lborder dt-center">' + d.scores.grouping_precision_LANG1+ '</td>'+
        '<td class="dt-center">' + d.scores.grouping_recall_LANG1+ '</td>'+
        '<td class="dt-center">' + d.scores.grouping_fscore_LANG1+ '</td>'+
        '<td class="lborder dt-center">' + d.scores.token_precision_LANG1+ '</td>'+
        '<td class="dt-center">' + d.scores.token_recall_LANG1+ '</td>'+
        '<td class="dt-center">' + d.scores.token_fscore_LANG1+ '</td>'+
        '<td class="lborder dt-center">' + d.scores.type_precision_LANG1+ '</td>'+
        '<td class="dt-center">' + d.scores.type_recall_LANG1+ '</td>'+
        '<td class="dt-center">' + d.scores.type_fscore_LANG1+ '</td>'+
        '<td class="lborder dt-center">' + d.scores.boundary_precision_LANG1+ '</td>'+
        '<td class="dt-center">' + d.scores.boundary_recall_LANG1+ '</td>'+
        '<td class="dt-center">' + d.scores.boundary_fscore_LANG1+ '</td>'+
        '<td class="lborder dt-center">' + d.scores.ned_LANG1+ '</td>'+
        '<td class="dt-center">' + d.scores.coverage_LANG1+ '</td>'+
        '<td class="dt-center">' + d.scores.n_words_LANG1+ '</td>'+
        '<td class="dt-center">' + d.scores.n_pairs_LANG1+ '</td>'+

        '</tr>'+

        '<tr>'+
        '<th>LANG2</th>'+
        '<td class="lborder dt-center">' + d.scores.grouping_precision_LANG2+ '</td>'+
        '<td class="dt-center">' + d.scores.grouping_recall_LANG2+ '</td>'+
        '<td class="dt-center">' + d.scores.grouping_fscore_LANG2+ '</td>'+
        '<td class="lborder dt-center">' + d.scores.token_precision_LANG2+ '</td>'+
        '<td class="dt-center">' + d.scores.token_recall_LANG2+ '</td>'+
        '<td class="dt-center">' + d.scores.token_fscore_LANG2+ '</td>'+
        '<td class="lborder dt-center">' + d.scores.type_precision_LANG2+ '</td>'+
        '<td class="dt-center">' + d.scores.type_recall_LANG2+ '</td>'+
        '<td class="dt-center">' + d.scores.type_fscore_LANG2+ '</td>'+
        '<td class="lborder dt-center">' + d.scores.boundary_precision_LANG2+ '</td>'+
        '<td class="dt-center">' + d.scores.boundary_recall_LANG2+ '</td>'+
        '<td class="dt-center">' + d.scores.boundary_fscore_LANG2+ '</td>'+
        '<td class="lborder dt-center">' + d.scores.ned_LANG2+ '</td>'+
        '<td class="dt-center">' + d.scores.coverage_LANG2+ '</td>'+
        '<td class="dt-center">' + d.scores.n_words_LANG2+ '</td>'+
        '<td class="dt-center">' + d.scores.n_pairs_LANG2+ '</td>'+

        '</tr>'+

        '</tbody>' +
        '</table>' +
        '</div>'
}