import {get, htmlAudioPlayer} from '../misc.js';

function speechSamples(data){
    return '<h5>Speech synthesis samples</h5>' +
        '<table style="padding-left:50px;">' +
        '<thead> <tr>' +
        '<th class="dt-center">Language</th>' +
        '<th class="dt-center">Utterance</th>' +
        '<th class="dt-center">Source</th>' +
        '<th class="dt-center">Target</th>' +
        '<th class="dt-center">Synthesis</th>' +
        '</tr></thead><tbody><tr>' +
        '<th rowspan="2">Surprise</th>' +
        '<th class="dt-center">117311</th>' +
        '<td class="dt-center">' + htmlAudioPlayer('surprise_1_source.wav') + '</td>'+
        '<td class="dt-center">' + htmlAudioPlayer('surprise_1_target.wav') + '</td>'+
        '<td class="dt-center">' + htmlAudioPlayer(get(() => data.extras.audio_samples.austronesian.sample_1)) + '</td>'+
        '<tr>' +
        '<th class="dt-center">117301</th>' +
        '<td class="dt-center">' + htmlAudioPlayer('surprise_2_source.wav') + '</td>'+
        '<td class="dt-center">' + htmlAudioPlayer('surprise_2_target.wav') + '</td>'+
        '<td class="dt-center">' + htmlAudioPlayer(get(() => data.extras.audio_samples.austronesian.sample_2)) + '</td>'+
        '</tr><tr>' +
        '<th rowspan="2">English</th>' +
        '<th class="dt-center">1478639117</th>' +
        '<td class="dt-center">' + htmlAudioPlayer('english_1_source.wav') + '</td>'+
        '<td class="dt-center">' + htmlAudioPlayer('english_1_target.wav') + '</td>'+
        '<td class="dt-center">' + htmlAudioPlayer(get(() => data.extras.audio_samples.english.sample_1)) + '</td>'+
        '<tr>' +
        '<th class="dt-center">2436309351</th>' +
        '<td class="dt-center">' + htmlAudioPlayer('english_2_source.wav') + '</td>'+
        '<td class="dt-center">' + htmlAudioPlayer('english_2_target.wav') + '</td>'+
        '<td class="dt-center">' + htmlAudioPlayer(get(() => data.extras.audio_samples.english.sample_2)) + '</td>'+

        '</tr></tbody></table>';
}

function detailedBitRate(data) {
    return '<h5>Detailed bitrate</h5>' +
        '<table style="padding-left:50px;">' +
        '<thead> <tr>' +
        '<th></th>' +
        '<th class="dt-center">Test</th>' +
        '<th class="dt-center">Auxiliary 1</th>' +
        '<th class="dt-center">Auxiliary 2</th>' +
        '</tr></thead><tbody>' +
        '<tr>' +
        '<th>Surprise</th>' +
        '<td class="dt-center">' + get(() => data.extras.detailed_scores.bitrate.austronesian.test_bitrate, "-") + '</td>' +
        '<td class="dt-center">' + get(() => data.extras.detailed_scores.bitrate.austronesian.auxiliary1_bitrate, "-") + '</td>' +
        '<td class="dt-center">' + get(() => data.extras.detailed_scores.bitrate.austronesian.auxiliary2_bitrate, "-") + '</td>' +
        '</tr><tr>' +
        '<th>English</th>' +
        '<td class="dt-center">' + get(() => data.extras.detailed_scores.bitrate.english.test_bitrate, "-") + '</td>' +
        '<td class="dt-center">' + get(() => data.extras.detailed_scores.bitrate.english.auxiliary1_bitrate, "-") + '</td>' +
        '<td class="dt-center">' + get(() => data.extras.detailed_scores.bitrate.english.auxiliary2_bitrate, "-") + '</td>' +
        '</tr></tbody></table>';
}

function detailedABX(data){
    return '<h5>Detailed ABX</h5>' +
        '<table style="padding-left:50px;">' +
        '<thead> <tr>' +
        '<th></th>' +
        '<th  class="dt-center" colspan="3">Surprise</th>' +
        '<th  class="dt-center" colspan="3">English</th>' +
        '</tr>' +
        '<tr>' +
        '<th></th>' +
        '<th class="dt-center">Test</th>' +
        '<th class="dt-center">Auxiliary 1</th>' +
        '<th class="dt-center">Auxiliary 2</th>' +
        '<th class="dt-center">Test</th>' +
        '<th class="dt-center">Auxiliary 1</th>' +
        '<th class="dt-center">Auxiliary 2</th>' +
        '</tr></thead><tbody>' +
        '<tr><th>DTW cosine</th>'+
        // Surprise lang
        '<td class="dt-center">' + get(() => data.extras.detailed_scores.abx.austronesian.test_abx_dtw_cosine, "-") + '</td>'+
        '<td class="dt-center">' + get(() => data.extras.detailed_scores.abx.austronesian.auxiliary1_abx_dtw_cosine, "-") + '</td>'+
        '<td class="dt-center">' + get(() => data.extras.detailed_scores.abx.austronesian.auxiliary2_abx_dtw_cosine, "-") + '</td>'+
        // English
        '<td class="dt-center">' + get(() => data.extras.detailed_scores.abx.english.test_abx_dtw_cosine, "-") + '</td>'+
        '<td class="dt-center">' + get(() => data.extras.detailed_scores.abx.english.auxiliary1_abx_dtw_cosine, "-") + '</td>'+
        '<td class="dt-center">' + get(() => data.extras.detailed_scores.abx.english.auxiliary2_abx_dtw_cosine, "-") + '</td>'+
        '</tr><tr>'+
        '<th>DTW KL</th>'+
        // Surprise lang
        '<td class="dt-center">' + get(() => data.extras.detailed_scores.abx.austronesian.test_abx_dtw_kl, "-") + '</td>'+
        '<td class="dt-center">' + get(() => data.extras.detailed_scores.abx.austronesian.auxiliary1_abx_dtw_kl, "-") + '</td>'+
        '<td class="dt-center">' + get(() => data.extras.detailed_scores.abx.austronesian.auxiliary2_abx_dtw_kl, "-") + '</td>'+
        // English
        '<td class="dt-center">' + get(() => data.extras.detailed_scores.abx.english.test_abx_dtw_kl, "-") + '</td>'+
        '<td class="dt-center">' + get(() => data.extras.detailed_scores.abx.english.auxiliary1_abx_dtw_kl, "-") + '</td>'+
        '<td class="dt-center">' + get(() => data.extras.detailed_scores.abx.english.auxiliary2_abx_dtw_kl, "-") + '</td>'+
        '</tr><tr>'+
        '<th>Leveinshtein</th>'+
        // Surprise lang
        '<td class="dt-center">' + get(() => data.extras.detailed_scores.abx.austronesian.test_abx_levenshtein, "-") + '</td>'+
        '<td class="dt-center">' + get(() => data.extras.detailed_scores.abx.austronesian.auxiliary1_abx_levenshtein, "-") + '</td>'+
        '<td class="dt-center">' + get(() => data.extras.detailed_scores.abx.austronesian.auxiliary2_abx_levenshtein, "-") + '</td>'+
        // English
        '<td class="dt-center">' + get(() => data.extras.detailed_scores.abx.english.test_abx_levenshtein, "-") + '</td>'+
        '<td class="dt-center">' + get(() => data.extras.detailed_scores.abx.english.auxiliary1_abx_levenshtein, "-") + '</td>'+
        '<td class="dt-center">' + get(() => data.extras.detailed_scores.abx.english.auxiliary2_abx_levenshtein, "-") + '</td>'+
        '</tr></tbody></table>';
}

export function tts019DetailedResults (data){
    return '<div style="float:left; width:100%;">' +
        '<h4>Detailed Results</h4>' +
        '<table>' +
        '<tr>' +
        '<td class="dt-center">Auxiliary embedding 1 description:</td>' +
        '<td class="dt-center">'+ get(() => data.extras.extra_description[0], "-") + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td class="dt-center">Auxiliary embedding 2 description:</td>' +
        '<td class="dt-center">'+ get(() => data.extras.extra_description[1], "-") + '</td>' +
        '</tr></table>' +
        '<div style="margin-bottom: 10px;"><br/></div>' +
        `<br/>${detailedABX(data)}<br/>${detailedBitRate(data)}<br/>${speechSamples(data)}` +
        '<div style="margin-bottom: 25px;"><br/></div>';
}