import {_} from '../misc.js'
/** Options for the tde15 leaderboard **/
export const tde15 = {
    paging: false,
    info: false,
    searching: false,
    ajax: {
        url: null,
        type: 'GET',
        cache: true
    },
    columnDefs: [
        { targets: "_all", className: "dt-center" },
        {
            render: function(data, type, ron){
                if(data){
                    return _(data);
                }
                return "-"
            },
            targets: [4,5,6,7,8,9]
        }
    ],
    columns: [
        {
            data: "index", orderable: true,
            className: "lborder dt-center"
        },
        {
            data: null, orderable: false,
            className: "details-control",
            defaultContent: ""
        },
        {
            data: "publication.author_short", orderable: false,
            className: "dt-center"
        },
        {
            data: "model_id", orderable: false,
            className: "rborder dt-center"
        },
        /** English */
        {
            data: "scores.english.nlp.ned", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.english.nlp.coverage", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.english.nlp.nwords", orderable: true,
            className: "dt-center"
        },
        /** Xitsonga */
        {
            data: "scores.xitsonga.nlp.ned", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.xitsonga.nlp.coverage", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.xitsonga.nlp.nwords", orderable: true,
            className: "rborder dt-center"
        }
    ],
    order: [[0, 'asc']],
};

/** Options for the tde17 leaderboard **/
export const tde17 = {
    paging: false,
    info: false,
    searching: false,
    columnDefs: [
        { targets: "_all", className: "dt-center" },
        {
            render: function(data, type, ron){
                if(data){
                    return _(data);
                }
                return "-"
            },
            targets: [4,5,7,8,10,11,13,14,16,17]
        },
        {
            render: function(data, type, ron){
                if(data){
                    return _(data, {no_decimal: true});
                }
                return "-"
            },
            targets: [6,9,12,15,18]
        },
    ],
    columns: [
        {
            data: "index", orderable: true,
            className: "lborder dt-center"
        },
        {
            data: null, orderable: false,
            className: "details-control",
            defaultContent: ""
        },
        {
            data: "publication.author_short", orderable: false,
            className: "dt-center"
        },
        {
            data: "model_id", orderable: false,
            className: "rborder dt-center"
        },
        /** English */
        {
            data: "scores.english.nlp.ned", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.english.nlp.coverage", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.english.nlp.nwords", orderable: true,
            className: "dt-center"
        },
        /** french */
        {
            data: "scores.french.nlp.ned", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.french.nlp.coverage", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.french.nlp.nwords", orderable: true,
            className: "dt-center"
        },
        /** mandarin */
        {
            data: "scores.mandarin.nlp.ned", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.mandarin.nlp.coverage", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.mandarin.nlp.nwords", orderable: true,
            className: "dt-center"
        },
        /** german */
        {
            data: "scores.german.nlp.ned", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.german.nlp.coverage", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.german.nlp.nwords", orderable: true,
            className: "dt-center"
        },
        /** Wolof */
        {
            data: "scores.wolof.nlp.ned", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.wolof.nlp.coverage", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.wolof.nlp.nwords", orderable: true,
            className: " rborder dt-center"
        }
    ],
    order: [[0, 'asc']],
};
