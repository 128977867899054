import {
    zr2015_track1, zr2015_track2,
    zr2017_track1_across, zr2017_track1_within, zr2017_track2,
    zr2020_track1, zr2020_track3,
    zr2019,
    zr2021
} from "./archived.js";
import {abx15, abx17, abxLS, abxLS_v2} from "./abx.js";
import {tde15, tde17} from "./tde.js";
import {tts019, sLM21, sLM21_prosody} from "./other.js"


export const opts = {
    challenges: {
        zr2015_track1, zr2015_track2,
        zr2017_track1_across, zr2017_track1_within, zr2017_track2,
        zr2020_track1, zr2020_track3,
        zr2019,
        zr2021
    },
    abx15, abx17, abxLS, abxLS_v2,
    tde15, tde17,
    tts019,
    sLM21, sLM21_prosody,
}