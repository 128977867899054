/* Formatting function for row details - in zr2015 track1 */
export function format_track1_2015(d) {
    //var date = d.metadata.submitted_at.replace("T", " ").slice(0, -6);

    // `d` is the original data object for the row
    return '<h4>Publication</h4></br>' +
        d.metadata.reference + '</br>' +
        '<h4>Main Idea</h4></br>' +
        d.metadata.main_idea
}

/* Formatting function for row details - in zr2015 track2 */
export function format_track2_2015(d) {
    //var date = d.metadata.submitted_at.replace("T", " ").slice(0, -6);

    // `d` is the original data object for the row
    return '<h4>General information</h4>' +
        '<div style="float:left; width:50%;">' +
        '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">' +
        '<tr>' +
        '<td>Authors:</td>' +
        '<td>' + d.metadata.author + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td>Team:</td>' +
        '<td>' + d.metadata.team + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td>Results obtained after the end of the challenge:</td>' +
        '<td>' + d.metadata.after + '</td>' +
        '</tr>' +

        '</table>' +
        '</div>' +
        '<div style="float:right; width:100%;">' +
        '<h4>Detailed Results</h4>' +
        '<table cellpadding="2" cellspacing="0" border="0" style="padding-left:50px;">' +
        '<thead>' +
        '<tr>' +
        '<th rowspan="2"></th><th  class="dt-center" colspan="3">Grouping</th>' +
        '</th><th  class="dt-center" colspan="3">Token</th>' +
        '</th><th  class="dt-center" colspan="3">Type</th>' +
        '</th><th  class="dt-center" colspan="3">Boundary</th>' +
        '</th><th  class="dt-center" colspan="3">Matching</th>' +
        '</th><th  class="dt-center" colspan="2">NLP</th>' +
        '</tr>' +
        '<tr>' +
        '<th class="dt-center">Precision</th><th class="dt-center">Recall</th>' +
        '<th class="dt-center">Fscore</th>' +
        '<th class="dt-center">Precision</th><th class="dt-center">Recall</th>' +
        '<th class="dt-center">Fscore</th>' +
        '<th class="dt-center">Precision</th><th class="dt-center">Recall</th>' +
        '<th class="dt-center">Fscore</th>' +
        '<th class="dt-center">Precision</th><th class="dt-center">Recall</th>' +
        '<th class="dt-center">Fscore</th>' +
        '<th class="dt-center">Precision</th><th class="dt-center">Recall</th>' +
        '<th class="dt-center">Fscore</th>' +
        '<th class="dt-center">NED</th><th class="dt-center">Coverage</th>' +

        '</tr>' +
        '</thead>' +
        '<tbody>' +
        '<tr>' +
        '<th>English</th>' +
        '<td class="lborder dt-center">' + d.scores.grouping_prec_english + '</td>' +
        '<td class="dt-center">' + d.scores.grouping_rec_english + '</td>' +
        '<td class="dt-center">' + d.scores.grouping_F_english + '</td>' +
        '<td class="lborder dt-center">' + d.scores.token_prec_english + '</td>' +
        '<td class="dt-center">' + d.scores.token_rec_english + '</td>' +
        '<td class="dt-center">' + d.scores.token_F_english + '</td>' +
        '<td class="lborder dt-center">' + d.scores.type_prec_english + '</td>' +
        '<td class="dt-center">' + d.scores.type_rec_english + '</td>' +
        '<td class="dt-center">' + d.scores.type_F_english + '</td>' +
        '<td class="lborder dt-center">' + d.scores.boundary_prec_english + '</td>' +
        '<td class="dt-center">' + d.scores.boundary_rec_english + '</td>' +
        '<td class="dt-center">' + d.scores.boundary_F_english + '</td>' +
        '<td class="lborder dt-center">' + d.scores.matching_prec_english + '</td>' +
        '<td class="dt-center">' + d.scores.matching_rec_english + '</td>' +
        '<td class="dt-center">' + d.scores.matching_F_english + '</td>' +
        '<td class="lborder dt-center">' + d.scores.NED_english + '</td>' +
        '<td class="dt-center">' + d.scores.cov_english + '</td>' +

        '</tr>' +
        '<tr>' +
        '<th>Xitsonga</th>' +
        '<td class="lborder dt-center">' + d.scores.grouping_prec_xitsonga + '</td>' +
        '<td class="dt-center">' + d.scores.grouping_rec_xitsonga + '</td>' +
        '<td class="dt-center">' + d.scores.grouping_F_xitsonga + '</td>' +
        '<td class="lborder dt-center">' + d.scores.token_prec_xitsonga + '</td>' +
        '<td class="dt-center">' + d.scores.token_rec_xitsonga + '</td>' +
        '<td class="dt-center">' + d.scores.token_F_xitsonga + '</td>' +
        '<td class="lborder dt-center">' + d.scores.type_prec_xitsonga + '</td>' +
        '<td class="dt-center">' + d.scores.type_rec_xitsonga + '</td>' +
        '<td class="dt-center">' + d.scores.type_F_xitsonga + '</td>' +
        '<td class="lborder dt-center">' + d.scores.boundary_prec_xitsonga + '</td>' +
        '<td class="dt-center">' + d.scores.boundary_rec_xitsonga + '</td>' +
        '<td class="dt-center">' + d.scores.boundary_F_xitsonga + '</td>' +
        '<td class="lborder dt-center">' + d.scores.matching_prec_xitsonga + '</td>' +
        '<td class="dt-center">' + d.scores.matching_rec_xitsonga + '</td>' +
        '<td class="dt-center">' + d.scores.matching_F_xitsonga + '</td>' +
        '<td class="lborder dt-center">' + d.scores.NED_xitsonga + '</td>' +
        '<td class="dt-center">' + d.scores.cov_xitsonga + '</td>' +

        '</tr>' +

        '</tbody>' +
        '</table>' +
        '</div>'
}