import {get} from '../misc.js'

function urlFromBenchmark(benchmark){
    const config = window.configurations;
    let benchPath = config.benchmarks[`${benchmark}`];

    let result = config.origin;
    if(benchPath){
        result = `${config.origin}/${benchPath}`;
    }
    return result;
}

function basic_info(data){
    let submitted_by = get(() => data.submitted_by, "");
    if(submitted_by !== ""){
        submitted_by = '<tr>' +
            '<td class="dt-center"> Submitted_by:</td>' +
            '<td>' + submitted_by +  '</td>' +
            '</tr>';
    }

    return '<div style="float:left; width:50%;">' +
        '<table style="padding-left:50px;">' +
        '<tr>' +
        '<td class="dt-center">Date:</td>' +
        '<td class="dt-center">'+ get(() => data.submission_date, "-") + '</td>' +
        '<tr>' +
        '<td class="dt-center">Authors:</td>' +
        '<td class="dt-center">'+ get(() => data.publication.authors, "-") + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td class="dt-center">Affiliation:</td>' +
        '<td class="dt-center">'+
        get(() => data.publication.institution, "-") + ' <br/>' +
        get(() => data.publication.team_name, "-") +
        '</td></tr>' + submitted_by +
        '</table></div>';
}

function basic_side_info(data){
    let open_science = get(() => data.publication.open_science,false);
    let code = get(() => data.publication.code, "");
    if(code !== ""){
        code = '<tr>' +
            '<td class="dt-center"> CODE:</td>' +
            '<td> <a href="' + code +'"> '+ code+' </a></td>' +
            '</tr>';
        open_science = true;
    }
    let doi = get(() => data.publication.code, "");
    if(doi !== ""){
        doi = '<tr>' +
            '<td class="dt-center"> DOI:</td>' +
            '<td> <a href="https://www.doi.org/' + doi +'"> '+ doi +' </a></td>' +
            '</tr>'
    }

    if(open_science) open_science = "True";
    else open_science = "False";


    return '<div style="float:right; width:50%;">' +
    '<table style="padding-left:50px;">' +
        '<tr>' +
        '<td class="dt-center">Is open science?</td>'+
        '<td class="dt-center"> <b>'+ open_science +'</b></td></tr>' +
         code + doi +
    '</table>'  +
    '</div>';
}

function publication_info(data){
    let paper_title = get(() => data.publication.paper_title, "-");
    let paper_url = get(() => data.publication.paper_url, "-");
    let pub_year = get(() => data.publication.pub_year, "-");
    let authors = get(() => data.publication.authors, "-");

    if(paper_title === "-"){
        return "";
    }

    if(paper_url !== "-"){
        paper_url = '<tr><td>Link:</td>' +
            '<td> <a href="' + paper_url +'"> '+ paper_url +' </a></td>' +
            '</tr>';
    }

    if(pub_year !== "-"){
        pub_year = '<tr><td>Year:</td>' +
            '<td>' + Math.trunc(pub_year) +'</td>' +
            '</tr>';
    }

    if(authors !== "-"){
        authors = '<tr><td>Authors:</td>' +
            '<td>' + authors +'</td>' +
            '</tr>';
    }


    return '<div style="float:left; width:100%;">' +
        '<table>' +
        '<thead><tr><th rowspan="3"> Paper Reference </th></tr></thead>' +
        '<tbody><tr>' +
        '<td>Title:</td>' +
        '<td>' + paper_title +'</td>' +
        '</tr>' + authors + pub_year + paper_url +
        '</tbody></table></div>';
}

function submissionTechnicalDetails(data){
    let gpu_budget = get(() => data.details.gpu_budget, "");
    let benchmark = get(() => data.details.benchmarks, []);
    console.log("---");
    console.log(data.details.parameters);
    console.log("---");
    let parameters = data.details.parameters;

    let benchmarkView = ""
    if(benchmark.length > 0){
        benchmarkView = '<tr><td>Benchmarks:</td><td>';
        let b_url = '';
        let b = '';
        for(let i in benchmark){
            b = benchmark[i];
            b_url = urlFromBenchmark(b);
            benchmarkView += '<a href="' + b_url + '">' + b + '</a>, ';
        }
        benchmarkView += "</td></tr>";
    }

    let gpuBudgetView = "";
    if(gpu_budget !== ""){
        gpuBudgetView = '<tr><td>GPU budget:</td>' +
            '<td>' + gpu_budget +'</td>' +
            '</tr>';
    }

    let parametersView = '';
    if(Object.keys(parameters).length  > 0){
        parametersView = '<tr><td><b>Parameters:</b></td></tr>';

        for(const k in parameters){
            parametersView += '<tr><td>' + k +  ': </td>' +
                '<td>'+ parameters[k] + '</td></tr>';
        }
    }


    return '<div style="float:left; width:100%;">' +
        '<table>' +
        '<thead><tr><th rowspan="3"> Technical Details </th></tr></thead>' +
        '<tbody><tr>' +
        '<td>Train Set:</td>' +
        '<td>' + get(() => data.details.train_set, "-") +'</td>' +
        benchmarkView + gpuBudgetView + parametersView +
        '</tbody></table></div>';
}


export function getDetailView(data, extrasFN=[]) {
    console.log(data);
    let result = '<h4> Details </h4>' +
         basic_info(data)   + basic_side_info(data) +
        '<br/><div style="float:left; width:100%;">' +
        '<table>' +
        '<tr>' +
        '<td class="dt-center">Description:</td>' +
        '<td class="dt-center">'+ get(() => data.description, "-") + '</td>' +
        '</tr>' +
        '</table>' +
        '</div>'

    // add more details
    result += `${publication_info(data)} <br/> ${submissionTechnicalDetails(data)} <br/><br/>`;

    // add leaderboard extras
    extrasFN.forEach((fn) => result += fn(data))
    return result
}