import {audio_sample} from "./zr2019.js";

/* Formatting function for zr2020-2019  */
export function format_19 ( d ) {
    const date = d.metadata.submitted_at.replace("T", " ").slice(0, -6);

    // `d` is the original data object for the row
    return '<h4>General information</h4>'+
        '<div style="float:left; width:50%;">'+
        '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">'+
        '<tr>'+
        '<td>Authors:</td>'+
        '<td>'+d.metadata.author+'</td>'+
        '</tr>'+
        '<tr>'+
        '<td>Affiliation:</td>'+
        '<td>'+d.metadata.affiliation+'</td>'+
        '</tr>'+
        '<tr>'+
        '<td>Submission date:</td>'+
        '<td>'+date+'</td>'+
        '</tr>'+
        '</table>'+
        '</div>'+
        '<div style="float:right; width:50%;">'+
        '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">'+
        '<tr>'+
        '<td>Is open science?</td>'+
        '<td>'+d.metadata.open_source +'</td>'+
        '</tr>'+
        '<tr>'+
        '<td>Using external data?</td>'+
        '<td>'+d[2019].metadata["using external data"] +'</td>'+
        '</tr>'+
        '<tr>'+
        '<td>Using parallel train?</td>'+
        '<td>'+d[2019].metadata["using parallel train"] +'</td>'+
        '</tr>'+
        '</table>'+
        '</div>'+
        '<div style="float:right; width:100%;">'+
        '<br>' +
        '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">'+
        '<tr>'+
        '<td>System description:</td>'+
        '<td>'+d[2019].metadata["system description"] +'</td>'+
        '</tr>'+
        '<tr>'+
        '<td>Auxiliary embedding 1 description:</td>'+
        '<td>'+d[2019].metadata["auxiliary1 description"] +'</td>'+
        '</tr>'+
        '<tr>'+
        '<td>Auxiliary embedding 2 description:</td>'+
        '<td>'+d[2019].metadata["auxiliary2 description"] + '</td>'+
        '</tr>'+
        '</table>' +
        '</div>'+

        '<div style="float:right; width:100%;">'+
        '<h4>Detailed ABX</h4>' +

        '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">'+
        '<thead>' +
        '<tr>' +
        '<th rowspan="2"></th><th  class="dt-center" colspan="3">Surprise</th>' +
        '<th  class="dt-center" colspan="3">English</th>' +
        '</tr>' +
        '<tr>' +
        '<th class="dt-center">Test</th><th class="dt-center">Auxiliary 1</th>' +
        '<th class="dt-center">Auxiliary 2</th>' +
        '<th class="dt-center">Test</th><th class="dt-center">Auxiliary 1</th>' +
        '<th class="dt-center">Auxiliary 2</th>' +
        '</tr>' +
        '</thead>' +
        '<tbody>'+
        '<tr>'+
        '<th>DTW cosine</th>'+
        '<td class="dt-center">' + d[2019].surprise.details_abx.test.cosine + '</td>'+
        '<td class="dt-center">' + d[2019].surprise.details_abx.auxiliary_embedding1.cosine + '</td>'+
        '<td class="dt-center">' + d[2019].surprise.details_abx.auxiliary_embedding2.cosine + '</td>'+
        '<td class="dt-center">' + d[2019].english.details_abx.test.cosine + '</td>'+
        '<td class="dt-center">' + d[2019].english.details_abx.auxiliary_embedding1.cosine + '</td>'+
        '<td class="dt-center">' + d[2019].english.details_abx.auxiliary_embedding2.cosine + '</td>'+
        '</tr>'+
        '<tr>'+
        '<th>DTW KL</th>'+
        '<td class="dt-center">' + d[2019].surprise.details_abx.test.KL + '</td>'+
        '<td class="dt-center">' + d[2019].surprise.details_abx.auxiliary_embedding1.KL + '</td>'+
        '<td class="dt-center">' + d[2019].surprise.details_abx.auxiliary_embedding2.KL + '</td>'+
        '<td class="dt-center">' + d[2019].english.details_abx.test.KL + '</td>'+
        '<td class="dt-center">' + d[2019].english.details_abx.auxiliary_embedding1.KL + '</td>'+
        '<td class="dt-center">' + d[2019].english.details_abx.auxiliary_embedding2.KL + '</td>'+
        '</tr>'+
        '<tr>'+
        '<th>Leveinshtein</th>'+
        '<td class="dt-center">' + d[2019].surprise.details_abx.test.levenshtein + '</td>'+
        '<td class="dt-center">' + d[2019].surprise.details_abx.auxiliary_embedding1.levenshtein + '</td>'+
        '<td class="dt-center">' + d[2019].surprise.details_abx.auxiliary_embedding2.levenshtein + '</td>'+
        '<td class="dt-center">' + d[2019].english.details_abx.test.levenshtein + '</td>'+
        '<td class="dt-center">' + d[2019].english.details_abx.auxiliary_embedding1.levenshtein + '</td>'+
        '<td class="dt-center">' + d[2019].english.details_abx.auxiliary_embedding2.levenshtein + '</td>'+
        '</tr>'+
        '</tbody>' +
        '</table>' +

        '<h4>Detailed bitrate</h4>' +
        '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">'+
        '<thead>' +
        '<tr>' +
        '<th></th> <th class="dt-center">Test</th> <th>Auxiliary 1</th> <th>Auxiliary 2</th>' +
        '</tr>' +
        '</thead>' +
        '<tbody>'+
        '<tr>'+
        '<th>Surprise</th>' +
        '<td class="dt-center">' + d[2019].surprise.details_bitrate.test + '</td>'+
        '<td class="dt-center">' + d[2019].surprise.details_bitrate.auxiliary_embedding1 + '</td>'+
        '<td class="dt-center">' + d[2019].surprise.details_bitrate.auxiliary_embedding2 + '</td>'+
        '</tr>'+
        '<tr>'+
        '<th>English</th>' +
        '<td class="dt-center">' + d[2019].english.details_bitrate.test + '</td>'+
        '<td class="dt-center">' + d[2019].english.details_bitrate.auxiliary_embedding1 + '</td>'+
        '<td class="dt-center">' + d[2019].english.details_bitrate.auxiliary_embedding2 + '</td>'+
        '</tr>'+
        '</tbody>' +
        '</table>' +

        '<h4>Speech synthesis samples</h4>' +
        '<table cellpadding="5" cellspacing="5" border="0" style="padding-left:50px;">'+
        '<thead>' +
        '<tr>' +
        '<th>Language</th> <th>Utterance</th> <th> Source </th> <th> Target </th> <th>Synthesis</th>' +
        '</tr>' +
        '</thead>' +
        '<tbody>'+
        '<tr>' +
        '<th rowspan="2">Surprise</th>' +
        '<th class="dt-center">117311</th>' +
        '<td class="dt-center">' + audio_sample('surprise_1_source.wav') + '</td>'+
        '<td class="dt-center">' + audio_sample('surprise_1_target.wav') + '</td>'+
        '<td class="dt-center">' + audio_sample(d[2019].audio_samples.surprise_1) + '</td>'+
        '</tr>' +
        '<tr>' +
        '<th class="dt-center">117301</th>' +
        '<td class="dt-center">' + audio_sample('surprise_2_source.wav') + '</td>'+
        '<td class="dt-center">' + audio_sample('surprise_2_target.wav') + '</td>'+
        '<td class="dt-center">' + audio_sample(d[2019].audio_samples.surprise_2) + '</td>'+
        '</tr>' +
        '<tr>' +
        '<th rowspan="2">English</th>' +
        '<th class="dt-center">1478639117</th>' +
        '<td class="dt-center">' + audio_sample('english_1_source.wav') + '</td>'+
        '<td class="dt-center">' + audio_sample('english_1_target.wav') + '</td>'+
        '<td class="dt-center">' + audio_sample(d[2019].audio_samples.english_1) + '</td>'+
        '</tr>' +
        '<tr>' +
        '<th class="dt-center">2436309351</th>' +
        '<td class="dt-center">' + audio_sample('english_2_source.wav') + '</td>'+
        '<td class="dt-center">' + audio_sample('english_2_target.wav') + '</td>'+
        '<td class="dt-center">' + audio_sample(d[2019].audio_samples.english_2) + '</td>'+
        '</tr>' +
        '</tbody>' +
        '</table>' +
        '<br>' +
        '</div>'
}


/* Formatting function for row details - in zr2020-2017-t2 */
export function format_2017t2 ( d ) {
    //var date = d.metadata.submitted_at.replace("T", " ").slice(0, -6);

    // `d` is the original data object for the row
    return '<h4>General information</h4>'+
        '<div style="float:left; width:50%;">'+
        '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">'+
        '<tr>'+
        '<td>Authors:</td>'+
        '<td>'+d.metadata.author+'</td>'+
        '<td>System Description:</td>'+
        '<td>'+d.track2_2017.metadata["system description"]+'</td>'+
        '</tr>'+
        '</table>'+
        '<div style="float:right; width:100%;">'+
        '<h4>Detailed Results</h4>'+
        '<table cellpadding="2" cellspacing="0" border="0" style="padding-left:50px;">'+
        '<thead>' +
        '<tr>' +
        '<th rowspan="2"></th><th  class="dt-center" colspan="3">Grouping</th>' +
        '</th><th  class="dt-center" colspan="3">Boundary</th>' +
        '</th><th  class="dt-center" colspan="3">Token</th>' +
        '</th><th  class="dt-center" colspan="3">Type</th>' +
        '</th><th  class="dt-center" colspan="4">NLP</th>' +
        '</tr>' +
        '<tr>' +
        '<th class="dt-center">Precision</th><th class="dt-center">Recall</th>'+
        '<th class="dt-center">Fscore</th>' +
        '<th class="dt-center">Precision</th><th class="dt-center">Recall</th>'+
        '<th class="dt-center">Fscore</th>' +
        '<th class="dt-center">Precision</th><th class="dt-center">Recall</th>'+
        '<th class="dt-center">Fscore</th>' +
        '<th class="dt-center">Precision</th><th class="dt-center">Recall</th>'+
        '<th class="dt-center">Fscore</th>' +
        '<th class="dt-center">NED</th><th class="dt-center">Coverage</th>'+
        '<th class="dt-center">n-words</th><th class="dt-center">n-pairs</th>' +
        '</tr>' +
        '</thead>' +
        '<tbody>'+
        '<tr>'+
        '<th>English</th>'+
        '<td class="dt-center">' + d.track2_2017.english.details.grouping_precision+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.english.details.grouping_recall+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.english.details.grouping_fscore+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.english.details.boundary_precision+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.english.details.boundary_recall+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.english.details.boundary_fscore+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.english.details.token_precision + '</td>'+
        '<td class="dt-center">' + d.track2_2017.english.details.token_recall+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.english.details.token_fscore + '</td>'+
        '<td class="dt-center">' + d.track2_2017.english.details.type_precision+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.english.details.type_recall+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.english.details.type_fscore+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.english.details.ned+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.english.details.coverage+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.english.details.words+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.english.details.pairs+ '</td>' +
        '</tr>'+
        '<tr>'+
        '<th>French</th>'+
        '<td class="dt-center">' + d.track2_2017.french.details.grouping_precision+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.french.details.grouping_recall+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.french.details.grouping_fscore+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.french.details.boundary_precision+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.french.details.boundary_recall+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.french.details.boundary_fscore+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.french.details.token_precision + '</td>'+
        '<td class="dt-center">' + d.track2_2017.french.details.token_recall+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.french.details.token_fscore + '</td>'+
        '<td class="dt-center">' + d.track2_2017.french.details.type_precision+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.french.details.type_recall+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.french.details.type_fscore+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.french.details.ned+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.french.details.coverage+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.french.details.words+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.french.details.pairs+ '</td>' +
        '</tr>'+

        '<tr>'+
        '<th>Mandarin</th>'+
        '<td class="dt-center">' + d.track2_2017.mandarin.details.grouping_precision+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.mandarin.details.grouping_recall+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.mandarin.details.grouping_fscore+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.mandarin.details.boundary_precision+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.mandarin.details.boundary_recall+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.mandarin.details.boundary_fscore+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.mandarin.details.token_precision + '</td>'+
        '<td class="dt-center">' + d.track2_2017.mandarin.details.token_recall+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.mandarin.details.token_fscore + '</td>'+
        '<td class="dt-center">' + d.track2_2017.mandarin.details.type_precision+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.mandarin.details.type_recall+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.mandarin.details.type_fscore+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.mandarin.details.ned+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.mandarin.details.coverage+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.mandarin.details.words+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.mandarin.details.pairs+ '</td>' +
        '</tr>'+
        '<tr>'+
        '<th>LANG1</th>'+
        '<td class="dt-center">' + d.track2_2017.LANG1.details.grouping_precision+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG1.details.grouping_recall+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG1.details.grouping_fscore+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG1.details.boundary_precision+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG1.details.boundary_recall+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG1.details.boundary_fscore+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG1.details.token_precision + '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG1.details.token_recall+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG1.details.token_fscore + '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG1.details.type_precision+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG1.details.type_recall+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG1.details.type_fscore+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG1.details.ned+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG1.details.coverage+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG1.details.words+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG1.details.pairs+ '</td>' +
        '</tr>'+
        '<tr>'+
        '<th>LANG2</th>'+
        '<td class="dt-center">' + d.track2_2017.LANG2.details.grouping_precision+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG2.details.grouping_recall+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG2.details.grouping_fscore+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG2.details.boundary_precision+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG2.details.boundary_recall+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG2.details.boundary_fscore+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG2.details.token_precision + '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG2.details.token_recall+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG2.details.token_fscore + '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG2.details.type_precision+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG2.details.type_recall+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG2.details.type_fscore+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG2.details.ned+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG2.details.coverage+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG2.details.words+ '</td>'+
        '<td class="dt-center">' + d.track2_2017.LANG2.details.pairs+ '</td>' +
        '</tr>'+

        '</tbody>' +
        '</table>' +
        '</div>'
}