import {get, _} from "../misc";

function lexicalScoresByLength(data){
    const length_data = get(() => data.extras.lexical.by_length, []);
    if(length_data.length === 0){
        return "";
    }

    let results = '<table style="border: 1px solid #0b0b0b; display: inline-block;"> ' +
        '<thead> <tr>' +
        '<th style="border-right: 1px solid #000;text-align: center; vertical-align: middle;"> Set</th> ' +
        '<th colspan="3" style="border-right: 1px solid #000;text-align: center; vertical-align: middle;"> dev </th>' +
        '<th colspan="3" style="text-align: center; vertical-align: middle;"> test  </th>' +
        '</tr>' +
        '<tr>' +
        '<th class="dt-center" style="border-right: 1px solid #000;">length</th> ' +
        '<th class="dt-center">score</th> ' +
        '<th class="dt-center">std</th> ' +
        '<th class="dt-center" style="border-right: 1px solid #000;">n</th>' +
        '<th class="dt-center">score</th> ' +
        '<th class="dt-center">std</th> ' +
        '<th class="dt-center">n</th>' +
        '</tr></thead>' +
        '<tbody>';

    for(let key in length_data){
        let items = length_data[key];
        results += '<tr>' +
            '<td class="dt-center" style="border-right: 1px solid #000;">' + items["length"] + '</td>' +
            '<td class="dt-center">' + _(items["dev_score"]) + '</td>' +
            '<td class="dt-center">' + _(items["dev_std"]) + '</td>' +
            '<td class="dt-center" style="border-right: 1px solid #000;">' + items["dev_n"] + '</td>' +
            '<td class="dt-center">' + _(items["test_score"]) + '</td>' +
            '<td class="dt-center">' + _(items["test_std"]) + '</td>' +
            '<td class="dt-center">' + items["test_n"] + '</td>' +
            '</tr>';
    }

    results +=  '</tbody></table>';
    return results;
}

function lexicalScoresByFreq(data){
    const frequency_data = get(() => data.extras.lexical.by_frequency, []);
    if(frequency_data.length === 0){
        return "";
    }

    let results = '<table style="border: 1px solid #0b0b0b">' +
        '<thead><tr>' +
        '<th style="border-right: 1px solid #000;text-align: center; vertical-align: middle;"> Set</th> ' +
        '<th colspan="3" style="border-right: 1px solid #000;text-align: center; vertical-align: middle;"> dev </th>' +
        '<th colspan="3" style="text-align: center; vertical-align: middle;"> test  </th>' +
        '</tr><tr>' +
        '<td class="dt-center" style="border-right: 1px solid #000;">frequency</th>' +
        '<td class="dt-center">score</th>' +
        '<td class="dt-center">std</th>' +
        '<td class="dt-center" style="border-right: 1px solid #000;">n</th>' +
        '<td class="dt-center">score</th>' +
        '<td class="dt-center">std</th>' +
        '<td class="dt-center">n</th>' +
        '</tr></thead> <tbody>';

    for(let key in frequency_data){
        let items = frequency_data[key];
        results += '<tr>' +
            '<td class="dt-center" style="border-right: 1px solid #000;">' + items.frequency + '</td>' +
            '<td class="dt-center">' + _(items["dev_score"]) + '</td>' +
            '<td class="dt-center">' + _(items["dev_std"]) + '</td>' +
            '<td class="dt-center" style="border-right: 1px solid #000;">' + items["dev_n"] + '</td>' +
            '<td class="dt-center">' + _(items["test_score"]) + '</td>' +
            '<td class="dt-center">' + _(items["test_std"]) + '</td>' +
            '<td class="dt-center">' + items["test_n"] + '</td>' +
            '</tr>';
    }

    results +=  '</tbody></table>';
    return results;
}

function syntacticDetails(data){
    const syntactic_data = get(() => data.extras.syntactic, []);
    if(syntactic_data.length === 0){
        return "";
    }

    let results = '<table style="border: 1px solid #0b0b0b;"> ' +
        '<thead><tr>' +
        '<th style="border-right: 1px solid #000;text-align: center; vertical-align: middle;"> Set</th> ' +
        '<th colspan="3" style="border-right: 1px solid #000;text-align: center; vertical-align: middle;"> dev </th>' +
        '<th colspan="3" style="text-align: center; vertical-align: middle;"> test  </th>' +
        '</tr>' +
        '<tr>' +
        '<th class="dt-center" style="border-right: 1px solid #0b0b0b;">type</th>' +
        '<th class="dt-center">score</th> ' +
        '<th class="dt-center">std</th>' +
        '<th class="dt-center" style="border-right: 1px solid #0b0b0b;">n</th>' +
        '<th class="dt-center">score</th> ' +
        '<th class="dt-center">std</th>' +
        '<th class="dt-center">n</th> ' +
        '</tr></thead>' +
        '<tbody>';

    for(let key in syntactic_data){
        let items = syntactic_data[key];
        results += '<tr>' +
            '<td class="dt-center" style="border-right: 1px solid #000;">' + _(items["typeset"]) + '</td>' +
            '<td class="dt-center">' + _(items["dev_score"]) + '</td>' +
            '<td class="dt-center">' + _(items["dev_std"]) + '</td>' +
            '<td class="dt-center" style="border-right: 1px solid #000;">' + items["dev_n"] + '</td>' +
            '<td class="dt-center">' + _(items["test_score"]) + '</td>' +
            '<td class="dt-center">' + _(items["test_std"]) + '</td>' +
            '<td class="dt-center">' + items["test_n"] + '</td>' +
            '</tr>';
    }

    results +=  '</tbody></table>';
    return results;
}

function semanticCorrelations(data){
    const semantic_data = get(() => data.extras.semantic, []);
    if(semantic_data.length === 0){
        return "";
    }

    let results = '<table style="border: 1px solid #0b0b0b">' +
        '<thead>' +
        '<tr>' +
        '<td class="dt-center">set</th>' +
        '<td class="dt-center">dataset</th>' +
        '<td class="dt-center">librispeech</th>' +
        '<td class="dt-center">synthetic</th>' +
        '</tr>' +
        '</thead><tbody>';

    for(let key in semantic_data){
        let items = semantic_data[key];
        results += '<tr>' +
            '<td class="dt-center">'  + items.set + '</td>' +
            '<td class="dt-center">' + items.dataset + '</td>' +
            '<td class="dt-center">' + _(items.librispeech) + '</td>' +
            '<td class="dt-center">' + _(items.synthetic) + '</td>' +
            '</tr>';
    }

    results +=  '</tbody></table>';
    return results;
}

export function sLM21DetailedResults (data){
    return '<div style="float:left; width:100%;"><h4>Detailed Results</h4></div>' +
        '<div style="float:left; width: 100%"><h5> Lexical Scores by length </h5></div>' +
        `<div style="float:left; width: 50%""> ${lexicalScoresByLength(data)}</div>` +
        `<div style="float:right; width: 50%;"> <h5> Lexical Scores by frequency </h5> ${lexicalScoresByFreq(data)}</div>` +
        `<div style="float:left; width: 100%;"> <h5> Syntactic </h5> ${syntacticDetails(data)}</div>` +
        `<div style="float:left; width: 100%;"> <h5> Semantic Correlations </h5> ${semanticCorrelations(data)}</div>` +
        '<div style="margin-bottom: 25px;"><br/></div>';
}