import {get, _} from '../misc.js'

function tdeDetailedResultsData(data){
    const lang = Object.keys(get(() => data.scores, {}));
    const get_format = (value, def_val) => _(get(value, def_val))
    let result = "";
    for(let lng of lang){
        result += '<tr>' +
            `<th>${lng}</th>` +
            // Grouping
            `<th class="lborder dt-center"> ${get_format(() => data.scores[lng].grouping.precision, "-")}</th>`  +
            `<th class="dt-center"> ${get_format(() => data.scores[lng].grouping.recall, "-")}</th>`  +
            `<th class="dt-center"> ${get_format(() => data.scores[lng].grouping.fscore, "-")}</th>`  +
            // Token
            `<th class="lborder dt-center"> ${get_format(() => data.scores[lng].token.precision, "-")}</th>`  +
            `<th class="dt-center"> ${get_format(() => data.scores[lng].token.recall, "-")}</th>`  +
            `<th class="dt-center"> ${get_format(() => data.scores[lng].token.fscore, "-")}</th>`  +
            // Type
            `<th class="lborder dt-center"> ${get_format(() => data.scores[lng].type.precision, "-")}</th>`  +
            `<th class="dt-center"> ${get_format(() => data.scores[lng].type.recall, "-")}</th>`  +
            `<th class="dt-center"> ${get_format(() => data.scores[lng].type.fscore, "-")}</th>`  +
            // Boundary
            `<th class="lborder dt-center"> ${get_format(() => data.scores[lng].boundary.precision, "-")}</th>`  +
            `<th class="dt-center"> ${get_format(() => data.scores[lng].boundary.recall, "-")}</th>`  +
            `<th class="dt-center"> ${get_format(() => data.scores[lng].boundary.fscore, "-")}</th>`  +
            // Matching
            `<th class="lborder dt-center"> ${get_format(() => data.scores[lng].matching.precision, "-")}</th>`  +
            `<th class="dt-center"> ${get_format(() => data.scores[lng].matching.recall, "-")}</th>`  +
            `<th class="dt-center"> ${get_format(() => data.scores[lng].matching.fscore, "-")}</th>`  +
            // NLP
            `<th class="lborder dt-center"> ${get_format(() => data.scores[lng].nlp.ned, "-")}</th>`  +
            `<th class="dt-center"> ${get_format(() => data.scores[lng].nlp.coverage, "-")}</th>`  +
            `<th class="dt-center"> ${get_format(() => data.scores[lng].nlp.nwords, "-")}</th>`  +
            `<th class="dt-center"> ${get_format(() => data.scores[lng].nlp.npairs, "-")}</th>`  +
            '</tr>';
    }

    return result;
}

export function tdeDetailedResults (data){
    return '<div style="float:left; width:100%;">' +
    '<h4>Detailed Results</h4>' +
    '<table cellpadding="2" cellspacing="0" border="0" style="padding-left:50px;">' +
    '<thead><tr>' +
    '<th></th>' +
    '<th  class="dt-center" colspan="3">Grouping</th>' +
    '<th  class="dt-center" colspan="3">Token</th>' +
    '<th  class="dt-center" colspan="3">Type</th>' +
    '<th  class="dt-center" colspan="3">Boundary</th>' +
    '<th  class="dt-center" colspan="3">Matching</th>' +
    '<th  class="dt-center" colspan="4">NLP</th>' +
    '</tr>' +
    '<tr>' +
    '<th>Lang.</th>' +
    '<th class="dt-center">Precision</th>' +
    '<th class="dt-center">Recall</th>' +
    '<th class="dt-center">Fscore</th>' +
    '<th class="dt-center">Precision</th>' +
    '<th class="dt-center">Recall</th>' +
    '<th class="dt-center">Fscore</th>' +
    '<th class="dt-center">Precision</th>' +
    '<th class="dt-center">Recall</th>' +
    '<th class="dt-center">Fscore</th>' +
    '<th class="dt-center">Precision</th>' +
    '<th class="dt-center">Recall</th>' +
    '<th class="dt-center">Fscore</th>' +
    '<th class="dt-center">Precision</th>' +
    '<th class="dt-center">Recall</th>' +
    '<th class="dt-center">Fscore</th>' +
    '<th class="dt-center">NED</th>' +
    '<th class="dt-center">Coverage</th>' +
    '<th class="dt-center">N-Words</th>' +
    '<th class="dt-center">N-Pairs</th>' +
    '</tr>' +
    '</thead>' +
    '<tbody>' +
    tdeDetailedResultsData(data) +
    '</tbody></table></div>' +
    '<div style="margin-bottom: 25px;"><br/></div>';
}