import {_, to_percent} from "../misc.js";

/** Options for the tts019 leaderboard **/
export const tts019 = {
    paging: false,
    info: false,
    searching: false,
    columnDefs: [
        {targets: "_all", className: "dt-center"},
        {
            render: function (data, type, ron) {
                if (data) {
                    return data;
                }
                return "-";
            },
            targets: [4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
        }
    ],
    columns: [
        {
            data: "index", orderable: true,
            className: "lborder dt-center"
        },
        {
            data: null, orderable: false,
            className: "details-control",
            defaultContent: ""
        },
        {
            data: "publication.author_short", orderable: false,
            className: "dt-center"
        },
        {
            data: "model_id", orderable: false,
            className: "rborder dt-center"
        },
        /** English */
        {
            data: "scores.english.mos", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.english.cer", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.english.similarity", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.english.abx", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.english.bitrate", orderable: true,
            className: "rborder dt-center"
        },
        /** austronesian */
        {
            data: "scores.austronesian.mos", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.austronesian.cer", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.austronesian.similarity", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.austronesian.abx", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.austronesian.bitrate", orderable: true,
            className: "rborder dt-center"
        },
    ],
    order: [[0, 'asc']],
};

/** Options for the sLM21-prosody leaderboard **/
export const sLM21_prosody = {
    paging: false,
    info: false,
    searching: false,
    columnDefs: [
        {targets: "_all", className: "dt-center"},
        {
            render: function (data, type, ron) {
                return "dev <br/> test";
            },
            targets: [5]
        },
        {
            render: function (data, type, ron) {
                if (data) {
                    const dev = data["dev"]["score"]
                    const test = data["test"]["score"]
                    return to_percent(dev) + "<br/>" + to_percent(test);
                }
                return "NaN <br/> NaN";
            },
            targets: [6, 7]
        }
    ],
    columns: [
        {
            data: "index", orderable: true,
            className: "lborder dt-center"
        },
        {
            data: null, orderable: false,
            className: "details-control",
            defaultContent: ""
        },
        {
            data: "publication.author_short", orderable: false,
            className: "dt-center"
        },
        {
            data: "model_id", orderable: false,
            className: "dt-center"
        },
        {
            data: "details.gpu_budget", orderable: false,
            className: "rborder dt-center"
        },
        {
            data: null, orderable: false,
            className: "rborder dt-center"
        },
        /** Protosyntax */
        {
            data: "scores.protosyntax", orderable: true,
            className: "rborder dt-center"
        },
        /** Lexical */
        {
            data: "scores.lexical", orderable: true,
            className: "rborder dt-center"
        }
    ],
    order: [[0, 'asc']]
};


/** Options for the sLM21 leaderboard **/
export const sLM21 = {
    paging: false,
    info: false,
    searching: false,
    columnDefs: [
        {targets: "_all", className: "dt-center"},
        {
            render: function (data, type, ron) {
                return "dev <br/> test";
            },
            targets: [5]
        },
        {
            render: function (data, type, ron) {
                if (data) {
                    const dev = data['dev']
                    const test = data['test']

                    return to_percent(dev) + "<br/>" + to_percent(test);
                }
                return "NaN <br/> NaN";
            },
            targets: [6, 7, 8]
        },
        {
            render: function (data, type, ron) {
                if (data) {
                    const dev = data['dev']
                    const test = data['test']

                    return _(dev) + "<br/>" + _(test);
                }
                return "- <br/> -";
            },
            targets: [9, 10, 11, 12]
        },
    ],
    columns: [
        {
            data: "index", orderable: true,
            className: "lborder dt-center"
        },
        {
            data: null, orderable: false,
            className: "details-control",
            defaultContent: ""
        },
        {
            data: "publication.author_short", orderable: false,
            className: "dt-center"
        },
        {
            data: "model_id", orderable: false,
            className: "dt-center"
        },
        {
            data: "details.gpu_budget", orderable: false,
            className: "rborder dt-center"
        },
        {
            data: null, orderable: false,
            className: "rborder dt-center"
        },
        /** Lexical */
        {
            data: "scores.lexical.all", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.lexical.in_vocab", orderable: true,
            className: "dt-center"
        },
        /** Syntactic */
        {
            data: "scores.syntactic", orderable: true,
            className: "dt-center"
        },
        /** Semantic */
        {
            data: "scores.semantic.normal.synthetic", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.semantic.normal.librispeech", orderable: true,
            className: "dt-center"
        },
        /** Semantic (Weighted) */
        {
            data: "scores.semantic.weighted.synthetic", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.semantic.weighted.librispeech", orderable: true,
            className: "rborder dt-center"
        }
    ],
    order: [[0, 'asc']]
};
