import {_, to_percent} from "../misc";

/** Options for the abx15 leaderboard **/
export const abx15 = {
    paging: false,
    info: false,
    searching: false,
    columnDefs: [
        { targets: "_all", className: "dt-center" },
    ],
    columns: [
        {
            data: "index", orderable: true,
            className: "lborder dt-center"
        },
        {
            data: null, orderable: false,
            className: "details-control",
            defaultContent: ""
        },
        {
            data: "publication.author_short", orderable: false,
            className: "dt-center"
        },
        {
            data: "model_id", orderable: false,
            className: "rborder dt-center"
        },
        {
            data: "scores.english.across", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.english.within", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.xitsonga.across", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.xitsonga.within", orderable: true,
            className: "rborder dt-center"
        }
    ],
    order: [[0, 'asc']],
};

/** Options for the abx17 leaderboard **/
export const abx17 = {
    paging: false,
    info: false,
    searching: false,
    columnDefs: [
        { targets: "_all", className: "dt-center" },
        {
            render: function (data, type, ron){
                if(data) {
                    return data;
                }
                return "-";
            },
            targets: [
                4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,
                21,22,23,24,25,26,27,28,29,30,31,32,33
            ]
        },
    ],
    columns: [
        {
            data: "index", orderable: true,
            className: "lborder dt-center"
        },
        {
            data: null, orderable: false,
            className: "details-control",
            defaultContent: ""
        },
        {
            data: "publication.author_short", orderable: false,
            className: "dt-center"
        },
        {
            data: "model_id", orderable: false,
            className: "rborder dt-center"
        },
        /** English **/
        {
            data: "scores.english.t_1s.across", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.english.t_1s.within", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.english.t_10s.across", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.english.t_10s.within", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.english.t_120s.across", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.english.t_120s.within", orderable: true,
            className: "dt-center"
        },
        /** French **/
        {
            data: "scores.french.t_1s.across", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.french.t_1s.within", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.french.t_10s.across", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.french.t_10s.within", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.french.t_120s.across", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.french.t_120s.within", orderable: true,
            className: "dt-center"
        },
        /** Mandarin **/
        {
            data: "scores.mandarin.t_1s.across", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.mandarin.t_1s.within", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.mandarin.t_10s.across", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.mandarin.t_10s.within", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.mandarin.t_120s.across", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.mandarin.t_120s.within", orderable: true,
            className: "dt-center"
        },
        /** German **/
        {
            data: "scores.german.t_1s.across", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.german.t_1s.within", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.german.t_10s.across", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.german.t_10s.within", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.german.t_120s.across", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.german.t_120s.within", orderable: true,
            className: "dt-center"
        },
        /** Wolof **/
        {
            data: "scores.wolof.t_1s.across", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.wolof.t_1s.within", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.wolof.t_10s.across", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.wolof.t_10s.within", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.wolof.t_120s.across", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.wolof.t_120s.within", orderable: true,
            className: "dt-center rborder"
        },
    ],
    order: [[0, 'asc']],
};

/** Options for the abxLS leaderboard **/
export const abxLS = {
    paging: false,
    info: false,
    searching: false,
    columnDefs: [
        { targets: "_all", className: "dt-center" },
        {
            render: function(data, type, ron){
                return "dev <br/> test";
            },
            targets: [5]
        },
        {
            render: function(data, type, ron){
                if(data){
                    const dev = data['dev']['within']
                    const test = data['test']['within']

                    return to_percent(dev) + "<br/>" + to_percent(test);
                }
                return "NaN <br/> NaN";
            },
            targets: [7,9]
        },
        {
            render: function(data, type, ron){
                if(data){
                    const dev = data['dev']['across']
                    const test = data['test']['across']

                    return to_percent(dev) + "<br/>" + to_percent(test);
                }
                return "NaN <br/> NaN";
            },
            targets: [6,8]
        },
    ],
    columns: [
        {
            data: "index", orderable: true,
            className: "lborder dt-center"
        },
        {
            data: null, orderable: false,
            className: "details-control",
            defaultContent: ""
        },
        {
            data: "publication.author_short", orderable: false,
            className: "dt-center"
        },
        {
            data: "model_id", orderable: false,
            className: "dt-center"
        },
        {
            data: "details.gpu_budget", orderable: false,
            className: "rborder dt-center"
        },
        {
            data: null, orderable: false,
            className: "rborder dt-center"
        },
        {
            data: "scores.clean", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.clean", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.other", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.other", orderable: true,
            className: "rborder dt-center"
        },
    ],
    order: [[0, 'asc']],
};


/** Options for the abxLS leaderboard **/
export const abxLS_v2 = {
    paging: false,
    info: false,
    searching: false,
    columnDefs: [
        { targets: "_all", className: "dt-center" },
        {
            render: function(data, type, ron){
                return "dev <br/> test";
            },
            targets: [5]
        },
        {
            /** scores.{triphone, phoneme}.{within,any}.{clean, other}.{across,within} */
            render: function(data, type, ron){
                if(data){
                    const dev = data['dev']['score']
                    const test = data['test']['score']

                    return to_percent(dev) + "<br/>" + to_percent(test);
                }
                return "NaN <br/> NaN";
            },
            targets: [6,7,8,9,10,11,12,13,14,15,16,17]
        },
    ],
    columns: [
        {
            data: "index", orderable: true,
            className: "lborder dt-center"
        },
        {
            data: null, orderable: false,
            className: "details-control",
            defaultContent: ""
        },
        {
            data: "publication.author_short", orderable: false,
            className: "dt-center"
        },
        {
            data: "model_id", orderable: false,
            className: "dt-center"
        },
        {
            data: "details.gpu_budget", orderable: false,
            className: "rborder dt-center"
        },
        {
            data: null, orderable: false,
            className: "rborder dt-center"
        },
        /** Score Columns */
        // triphone-within
        {
            data: "scores.triphone.within.clean.across", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.triphone.within.clean.within", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.triphone.within.other.across", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.triphone.within.other.within", orderable: true,
            className: "dt-center"
        },
        // phoneme-within
        {
            data: "scores.phoneme.within.clean.across", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.phoneme.within.clean.within", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.phoneme.within.other.across", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.phoneme.within.other.within", orderable: true,
            className: "dt-center"
        },
        // phoneme-any
        {
            data: "scores.phoneme.any.clean.across", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.phoneme.any.clean.within", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.phoneme.any.other.across", orderable: true,
            className: "dt-center"
        },
        {
            data: "scores.phoneme.any.other.within", orderable: true,
            className: "rborder dt-center"
        },
    ],
    order: [[0, 'asc']],
};