import {_, to_percent} from '../misc.js'

/** Options for the zr2015 track 1 leaderboard */
export const zr2015_track1 = {
    "paging": false,
    "info": false,
    "searching": false,
    ajax: {
        url: null,
        type: "GET",
        cache: true,
    },
    "columnDefs": [ { targets: "_all", className: 'dt-center' } ],
    "columns": [
        {
            "className": 'details-control',
            "orderable": false,
            "data": null,
            "defaultContent": ""
        },

        { "data": "metadata.author" },
        { "data": "metadata.bib" },
        { "data": "metadata.team" },
        { "data": "metadata.supervision"},
        { "data": "metadata.binary"},

        {
            "data": "scores.english_across",
            "className": 'lborder dt-center'
        },
        { "data": "scores.english_within" },
        {
            "data": "scores.xitsonga_across",
            "className": 'lborder dt-center'
        },
        { "data": "scores.xitsonga_within" },
    ],
    "order": [[1, 'asc']]
};

/** Options for the zr2015 track 2 leaderboard */
export const zr2015_track2 = {
    "paging": false,
    "info": false,
    "searching": false,
    ajax: {
        url: null,
        type: "GET",
        cache: true,
    },
    "columnDefs": [ { targets: "_all", className: 'dt-center' } ],
    "columns": [
        {
            "className": 'details-control',
            "orderable": false,
            "data": null,
            "defaultContent": ''
        },

        { "data": "metadata.author" },
        { "data": "metadata.team"},
        {
            "data": "scores.NED_english",
            "className": 'lborder dt-center'
        },
        { "data": "scores.cov_english" },
        { "data": "scores.boundary_F_english"},
        { "data": "scores.token_F_english"},
        { "data": "scores.type_F_english"},
        { "data": "scores.grouping_F_english"},

        {
            "data": "scores.NED_xitsonga",
            "className": 'lborder dt-center'
        },
        { "data": "scores.cov_xitsonga" },
        { "data": "scores.boundary_F_xitsonga"},
        { "data": "scores.token_F_xitsonga"},
        { "data": "scores.type_F_xitsonga"},
        { "data": "scores.grouping_F_xitsonga"},

    ],
    "order": [[1, 'asc']]
};

/** Options for the zr2017 track 1 across leaderboard */
export const zr2017_track1_across = {
    "paging": false,
    "info": false,
    "searching": false,
    ajax: {
        url: null,
        type: "GET",
        cache: true,
    },
    "columnDefs": [ { targets: "_all", className: 'dt-center' } ],
    "columns": [
        { "data": "metadata.author" },
        { "data": "metadata.affiliation" },
        { "data": "metadata.DOI"},
        { "data": "metadata.rank",
            "sType": "numWithNull" },

        {
            "data": "scores.english_1s",
            "className": 'lborder dt-center'
        },
        { "data": "scores.english_10s" },
        { "data": "scores.english_120s" },
        {
            "data": "scores.french_1s",
            "className": 'lborder dt-center'
        },
        { "data": "scores.french_10s" },
        { "data": "scores.french_120s" },
        {
            "data": "scores.mandarin_1s",
            "className": 'lborder dt-center'
        },
        { "data": "scores.mandarin_10s" },
        { "data": "scores.mandarin_120s" },
        {
            "data": "scores.LANG1_1s",
            "className": 'lborder dt-center'
        },
        { "data": "scores.LANG1_10s" },
        { "data": "scores.LANG1_120s" },

        {
            "data": "scores.LANG2_1s",
            "className": 'lborder dt-center'
        },
        { "data": "scores.LANG2_10s" },
        { "data": "scores.LANG2_120s" },
        {
            "data": "metadata.supervision",
            "className": 'lborder dt-center'
        },
    ],
    "order": [[1, 'asc']]
};

/** Options for the zr2017 track 1 within leaderboard */
export const zr2017_track1_within = {
    "paging": false,
    "info": false,
    "searching": false,
    ajax: {
        url: null,
        type: "GET",
        cache: true,
    },
    "columnDefs": [ { targets: "_all", className: 'dt-center' } ],
    "columns": [
        { "data": "metadata.author" },
        { "data": "metadata.affiliation" },
        { "data": "metadata.DOI"},
        { "data": "metadata.rank",
            "sType": "numWithNull" },

        {
            "data": "scores.english_1s",
            "className": 'lborder dt-center'
        },
        { "data": "scores.english_10s" },
        { "data": "scores.english_120s" },
        {
            "data": "scores.french_1s",
            "className": 'lborder dt-center'
        },
        { "data": "scores.french_10s" },
        { "data": "scores.french_120s" },
        {
            "data": "scores.mandarin_1s",
            "className": 'lborder dt-center'
        },
        { "data": "scores.mandarin_10s" },
        { "data": "scores.mandarin_120s" },
        {
            "data": "scores.LANG1_1s",
            "className": 'lborder dt-center'
        },
        { "data": "scores.LANG1_10s" },
        { "data": "scores.LANG1_120s" },

        {
            "data": "scores.LANG2_1s",
            "className": 'lborder dt-center'
        },
        { "data": "scores.LANG2_10s" },
        { "data": "scores.LANG2_120s" },
        {
            "data": "metadata.supervision",
            "className": 'lborder dt-center'
        },
    ],
    "order": [[1, 'asc']]
};

/** Options for the zr2017 track 2 leaderboard */
export const zr2017_track2 = {
    "paging": false,
    "info": false,
    "searching": false,
    ajax: {
        url: null,
        type: "GET",
        cache: true,
    },
    "columnDefs": [ { targets: "_all", className: 'dt-center' } ],
    "columns": [
        {
            "className": 'details-control',
            "orderable": false,
            "data": null,
            "defaultContent": ''
        },

        { "data": "metadata.author" },
        { "data": "metadata.DOI"},
        {
            "data": "scores.ned_english",
            "className": 'lborder dt-center'
        },
        { "data": "scores.coverage_english" },
        { "data": "scores.n_words_english" },
        {
            "data": "scores.ned_french",
            "className": 'lborder dt-center'
        },
        { "data": "scores.coverage_french" },
        { "data": "scores.n_words_french" },
        {
            "data": "scores.ned_mandarin",
            "className": 'lborder dt-center'
        },
        { "data": "scores.coverage_mandarin" },
        { "data": "scores.n_words_mandarin" },
        {
            "data": "scores.ned_LANG1",
            "className": 'lborder dt-center'
        },
        { "data": "scores.coverage_LANG1" },
        { "data": "scores.n_words_LANG1" },
        {
            "data": "scores.ned_LANG2",
            "className": 'lborder dt-center'
        },
        { "data": "scores.coverage_LANG2" },
        { "data": "scores.n_words_LANG2" },



    ],
    "order": [[1, 'asc']]
};

/** Options for the zr2019 leaderboard */
export const zr2019 = {
    "paging": false,
    "info": false,
    "searching": false,
    ajax: {
        url: null,
        type: "GET",
        cache: true,
    },
    "columnDefs": [ { targets: "_all", className: 'dt-center' } ],
    "columns": [
        {
            "className": 'details-control',
            "orderable": false,
            "data": null,
            "defaultContent": ''
        },
        { "data": "metadata.submission_index" },
        { "data": "metadata.author_short" },
        {
            "data": "scores.surprise_mos",
            "className": 'lborder dt-center'
        },
        { "data": "scores.surprise_cer" },
        { "data": "scores.surprise_similarity" },
        { "data": "scores.surprise_abx" },
        {
            "data": "scores.surprise_bitrate",
            "className": 'rborder dt-center'
        },
        { "data": "scores.english_mos" },
        { "data": "scores.english_cer" },
        { "data": "scores.english_similarity" },
        { "data": "scores.english_abx" },
        {
            "data": "scores.english_bitrate",
            "className": 'rborder dt-center'
        },
    ],
    "order": [[1, 'asc']]
};

/** Options for the zr2020 track1 (same format as 2019) leaderboard */
export const zr2020_track1 = {
    "paging": false,
    "info": false,
    "searching": false,
    ajax: {
        url: null,
        type: "GET",
        cache: true,
    },
    "columnDefs": [ { targets: "_all", className: 'dt-center' } ],
    "columns": [
        {
            "className": 'details-control',
            "orderable": false,
            "data": null,
            "defaultContent": ''
        },
        { "data": "metadata.submission_index" },
        { "data": "metadata.author_short" },
        {
            "data": "2019.surprise.scores.mos",
            "className": 'lborder dt-center'
        },
        { "data": "2019.surprise.scores.cer" },
        { "data": "2019.surprise.scores.similarity" },
        { "data": "2019.surprise.scores.abx" },
        {
            "data": "2019.surprise.scores.bitrate",
            "className": 'rborder dt-center'
        },
        { "data": "2019.english.scores.mos" },
        { "data": "2019.english.scores.cer" },
        { "data": "2019.english.scores.similarity" },
        { "data": "2019.english.scores.abx" },
        {
            "data": "2019.english.scores.bitrate",
            "className": 'rborder dt-center'
        },
    ],
    "order": [[1, 'asc']]
};

/** Options for the zr2020 track2 (same format as 2017-t1) leaderboard */
// No data
// export const zr2020_track2 = {};

/** Options for the zr2020 track3 (same format as 2017-t2) leaderboard */
export const zr2020_track3 = {
    "paging": false,
    "info": false,
    "searching": false,
    ajax: {
        url: null,
        type: "GET",
        cache: true,
    },
    "columnDefs": [ { targets: "_all", className: 'dt-center' } ],
    "columns": [
        {
            "className": 'details-control',
            "orderable": false,
            "data": null,
            "defaultContent": ''
        },

        { "data": "metadata.author_short" },
        {
            "data": "track2_2017.english.scores.ned",
            "className": 'lborder dt-center'
        },
        { "data": "track2_2017.english.scores.coverage"},
        { "data": "track2_2017.english.scores.words" },
        {
            "data": "track2_2017.french.scores.ned",
            "className": 'lborder dt-center'
        },
        { "data": "track2_2017.french.scores.coverage" },
        { "data": "track2_2017.french.scores.words" },
        {
            "data": "track2_2017.mandarin.scores.ned",
            "className": 'lborder dt-center'
        },
        { "data": "track2_2017.mandarin.scores.coverage" },
        { "data": "track2_2017.mandarin.scores.words" },
        {
            "data": "track2_2017.LANG1.scores.ned",
            "className": 'lborder dt-center'
        },
        { "data": "track2_2017.LANG1.scores.coverage" },
        { "data": "track2_2017.LANG1.scores.words"},
        {
            "data": "track2_2017.LANG2.scores.ned",
            "className": 'lborder dt-center'
        },
        { "data": "track2_2017.LANG2.scores.coverage" },
        { "data": "track2_2017.LANG2.scores.words"}


    ],
    "order": [[1, 'asc']]
};

/** Options for the zr2021 leaderboard */
export const zr2021 = {
    paging: false,
    info: false,
    searching: false,
    ajax: {
        url: null,
        type: "GET",
        cache: true,
    },
    columnDefs: [
        { targets: "_all", className: 'dt-center' },
        {
            render: function (data, type, ron){
                if(data) {
                    return _(data[0]) + "<br/>" + _(data[1]);
                }
                return "NaN <br/> NaN";
            },
            targets: [4]
        },
        {
            render: function(data, type, ron){
                if(data){
                    return to_percent(data[0]) + "<br/>" + to_percent(data[1]);
                }
                return "NaN <br/> NaN";
            },
            targets: [5, 6, 7, 8, 9, 10, 11]
        },
        {
            render: function (data, type, ron){
                if(data) {
                    return _(Math.abs(data[0])) + "<br/>" + _(Math.abs(data[1]))
                }
                return "NaN <br/> NaN";
            },
            targets: [12,13,14,15]
        }
    ],
    columns: [
        {
            data: "index", orderable: true,
            className: 'lborder dt-center'
        },
        {
            "className": 'details-control',
            "orderable": false,
            "data": null,
            "defaultContent": ''
        },
        {data: "author_label", orderable: false},
        {
            data: "more.description.gpu_budget", orderable: true,
            className: 'rborder dt-center'
        },
        {data: "set", orderable: false},
        {
            data: "phonetic_clean_within", orderable: true,
            type: "dualrow", className: 'lborder dt-center'
        },
        {
            data: "phonetic_other_within", orderable: true,
            type: "dualrow", className: 'dt-center'
        },
        {
            data: "phonetic_clean_across", orderable: true,
            className: 'lborder dt-center'
        },
        {
            data: "phonetic_other_across", orderable: true,
            type: "dualrow", className: 'rborder dt-center'
        },
        {
            data: "lexical_all", orderable: true,
            type: "dualrow", className: 'dt-center'
        },
        {
            data: "lexical_invocab", orderable: true,
            type: "dualrow", className: 'rborder dt-center'
        },
        {data: "syntactic", type: "dualrow", orderable: true},
        {
            data: "semantic_synthetic", orderable: true,
            type: "dualrow", className: 'lborder dt-center'
        },
        {
            data: "semantic_librispeech", orderable: true,
            type: "dualrow", className: 'rborder dt-center'
        },
        {
            data: "weighted_semantic_synthetic", orderable: true,
            type: "dualrow", className: 'dt-center'
        },
        {
            data: "weighted_semantic_librispeech", orderable: true,
            type: "dualrow", className: 'rborder dt-center'
        }
    ],
    order: [[0, 'asc']],
};