import {_} from '../../misc.js'
/** build table showing lexical details by length stats */
function by_length_body(data){
    let result = '';

    for(let key in data){
        let items = data[key];
        result += '<tr>' +
            '<td class="dt-center" style="border-right: 1px solid #000;">' + items["length"] + '</td>' +
            '<td class="dt-center">' + _(items["score_dev"]) + '</td>' +
            '<td class="dt-center">' + _(items["std_dev"]) + '</td>' +
            '<td class="dt-center" style="border-right: 1px solid #000;">' + items["n_dev"] + '</td>' +
            '<td class="dt-center">' + _(items["score_test"]) + '</td>' +
            '<td class="dt-center">' + _(items["std_test"]) + '</td>' +
            '<td class="dt-center">' + items["n_test"] + '</td>' +
            '</tr>';
    }
    return result;
}

/** build table showing lexical details by frequency stats */
function by_frequency_body(data){
    let result = ''
    for(let key in data){
        let items = data[key];
        result += '<tr>' +
            '<td class="dt-center" style="border-right: 1px solid #000;">' + items.frequency + '</td>' +
            '<td class="dt-center">' + _(items["score_dev"]) + '</td>' +
            '<td class="dt-center">' + _(items["std_dev"]) + '</td>' +
            '<td class="dt-center" style="border-right: 1px solid #000;">' + items["n_dev"] + '</td>' +
            '<td class="dt-center">' + _(items["score_test"]) + '</td>' +
            '<td class="dt-center">' + _(items["std_test"]) + '</td>' +
            '<td class="dt-center">' + items["n_test"] + '</td>' +
            '</tr>';
    }

    return result;
}

/** build table showing syntactic details data */
function syntactic_body(data){
    let result = '';

    for(let key in data){
        let items = data[key];
        result += '<tr>' +
            '<td class="dt-center" style="border-right: 1px solid #000;">' + _(items["type"]) + '</td>' +
            '<td class="dt-center">' + _(items["score_dev"]) + '</td>' +
            '<td class="dt-center">' + _(items["std_dev"]) + '</td>' +
            '<td class="dt-center" style="border-right: 1px solid #000;">' + items["n_dev"] + '</td>' +
            '<td class="dt-center">' + _(items["score_test"]) + '</td>' +
            '<td class="dt-center">' + _(items["std_test"]) + '</td>' +
            '<td class="dt-center">' + items["n_test"] + '</td>' +
            '</tr>'
    }
    return result;
}

/** build table showing correlations */
function correlations_body(data){
    let result = '';

    for(let key in data){
        let items = data[key];
        result += '<tr>' +
            '<td class="dt-center">'  + items.set + '</td>' +
            '<td class="dt-center">' + items.dataset + '</td>' +
            '<td class="dt-center">' + _(items.librispeech) + '</td>' +
            '<td class="dt-center">' + _(items.synthetic) + '</td>' +
            '</tr>';
    }

    return result;
}

/** build detailed view (opened on more button for each cell) */
export function getDetailView(data) {
    let date = data.more.description.submitted_at.replace("T", " ").slice(0, -6);

    let results = '<h4>General information</h4>' +
        '<div style="float:left; width:50%;">' +
        '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">' +
        '<tr>' +
        '<td class="dt-center">Authors:</td>' +
        '<td class="dt-center">'+ data.more.description.author + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td class="dt-center">Affiliation:</td>' +
        '<td class="dt-center">' + data.more.description.affiliation +'</td>'+
        '</tr>'+
        '<tr>'+
        '<td class="dt-center">Submission date:</td>'+
        '<td class="dt-center">'+date+'</td>'+
        '</tr>'+
        '</table>'+
        '</div>'+
        '<div style="float:right; width:50%;">' +
        '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">' +
        '<tr>'+
        '<td class="dt-center">Is open science?</td>'+
        '<td class="dt-center">'+data.more.description.open_source+'</td>'+
        '</tr>'+
        '<tr>'+
        '<td class="dt-center">GPU budget (in hours used to train)</td>'+
        '<td class="dt-center">'+ data.more.description.gpu_budget+'</td>'+
        '</tr>'+
        '</table>' +
        '</table>'+
        '</div>'+
        '<div style="float:right; width:100%;">'+
        '<br>' +
        '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">'+
        '<tr>'+
        '<td class="dt-center">System description:</td>'+
        '<td class="dt-center">'+ data.more.description.description +'</td>'+
        '</tr>'+
        '</table>' +
        '</div>';

    // Check for detailed stats
    if( 'lexical' in data.more && 'syntactic' in data.more && 'semantic' in data.more) {
        results += '<div style="float:left; width: 100%"> ' +
            '<h4>Detailed information</h4> </div>';

        /** Lexical Details */
        results += '<div style="float:left; width: 100%"><h5> Lexical Scores by length </h5></div>';
        results += '<div style="float:left; width: 50%"">' +
            '<table style="border: 1px solid #0b0b0b; display: inline-block;"> ' +
            '<thead> <tr>' +
            '<th style="border-right: 1px solid #000;text-align: center; vertical-align: middle;"> Set</th> ' +
            '<th colspan="3" style="border-right: 1px solid #000;text-align: center; vertical-align: middle;"> dev </th>' +
            '<th colspan="3" style="text-align: center; vertical-align: middle;"> test  </th>' +
            '</tr>' +
            '<tr>' +
            '<th class="dt-center" style="border-right: 1px solid #000;">length</th> ' +
            '<th class="dt-center">score</th> ' +
            '<th class="dt-center">std</th> ' +
            '<th class="dt-center" style="border-right: 1px solid #000;">n</th>' +
            '<th class="dt-center">score</th> ' +
            '<th class="dt-center">std</th> ' +
            '<th class="dt-center">n</th>' +
            '</tr></thead>' +
            '<tbody>' +
            by_length_body(data.more.lexical.by_length) +
            '</tbody>' +
            '</table> </div>';

        /** Lexical Scores by frequency */
        results += '<div style="float:right; width: 50%;">' +
            '<h5> Lexical Scores by frequency </h5>' +
            '<table style="border: 1px solid #0b0b0b">' +
            '<thead><tr>' +
            '<th style="border-right: 1px solid #000;text-align: center; vertical-align: middle;"> Set</th> ' +
            '<th colspan="3" style="border-right: 1px solid #000;text-align: center; vertical-align: middle;"> dev </th>' +
            '<th colspan="3" style="text-align: center; vertical-align: middle;"> test  </th>' +
            '</tr>' +
            '<tr>' +
            '<td class="dt-center" style="border-right: 1px solid #000;">frequency</th>' +
            '<td class="dt-center">score</th>' +
            '<td class="dt-center">std</th>' +
            '<td class="dt-center" style="border-right: 1px solid #000;">n</th>' +
            '<td class="dt-center">score</th>' +
            '<td class="dt-center">std</th>' +
            '<td class="dt-center">n</th>' +
            '</tr>' +
            '</thead> <tbody>' +
            by_frequency_body(data.more.lexical.by_frequency) +
            '</tbody> </table>' +
            '</table>' +
            '</div>';

        /** Syntactic Details */
        results += '<div style="float:left; width: 100%;">' +
            '<h5> Syntactic </h5>' +
            '<table style="border: 1px solid #0b0b0b;"> ' +
            '<thead><tr>' +
            '<th style="border-right: 1px solid #000;text-align: center; vertical-align: middle;"> Set</th> ' +
            '<th colspan="3" style="border-right: 1px solid #000;text-align: center; vertical-align: middle;"> dev </th>' +
            '<th colspan="3" style="text-align: center; vertical-align: middle;"> test  </th>' +
            '</tr>' +
            '<tr>' +
            '<th class="dt-center" style="border-right: 1px solid #0b0b0b;">type</th>' +
            '<th class="dt-center">score</th> ' +
            '<th class="dt-center">std</th>' +
            '<th class="dt-center" style="border-right: 1px solid #0b0b0b;">n</th>' +
            '<th class="dt-center">score</th> ' +
            '<th class="dt-center">std</th>' +
            '<th class="dt-center">n</th> ' +
            '</tr></thead>' +
            '<tbody>' +
            syntactic_body(data.more.syntactic) +
            '</tbody>' +
            '</table></div>';

        /** Semantic Details */
        results += '<div style="float:left; width: 100%;">' +
            '<h5> Semantic Correlations </h5>' +
            '<table style="border: 1px solid #0b0b0b">' +
            '<thead>' +
            '<tr>' +
            '<td class="dt-center">set</th>' +
            '<td class="dt-center">dataset</th>' +
            '<td class="dt-center">librispeech</th>' +
            '<td class="dt-center">synthetic</th>' +
            '</tr>' +
            '</thead><tbody>' +
            correlations_body(data.more.semantic) +
            '</tbody>' +
            '</table>' +
            '</div>';
    }

    results += '<div style="margin-bottom: 25px;"><br/></div>';
    return results;
}