
/** Generic leaderboard build function
 * args:
 *  leaderboard_id<str>: the html id of the table
 *  options<object>: the options object
 *  getDetailView<fn>: a function that returns the html detailed view of each row
 * **/
export function leaderboard(leaderboard_id, leaderboard_url, options, getDetailView, extraOptions=null) {
    console.log(`loading ${leaderboard_id} leaderboard`);

    // set the url
    options['ajax'] = {
            url: leaderboard_url,
            type: 'GET',
            cache: true
    };

    // build the table
    let table = $(`#${leaderboard_id}`).DataTable(options);

    // call extra options if provided
    if(extraOptions !== null){
        extraOptions(table, leaderboard_id, options, getDetailView);
    }

    // attach details listener
    $(`#${leaderboard_id} tbody`).on('click', 'td.details-control', function (){
        let tr = $(this).closest('tr');
        let row = table.row( tr );

        if ( row.child.isShown() ) {
            // This row is already open - close it
            row.child.hide();
            tr.removeClass('shown');
        }
        else {
            // Open this row
            row.child(getDetailView(row.data())).show();
            tr.addClass('shown');
        }
    } );
}