import {leaderboard} from './commons/build.js';
import {opts} from './commons/opt';
import {detailed_views} from './commons/details';
import {fixNaNSorting, dualRowSortFunction} from './commons/misc.js'

(function () {
    window.leaderboardJS =  {
        build_leaderboard: leaderboard,
        opts,
        detailed_views,
        misc_fn: {
            fixNaNSorting, dualRowSortFunction
        }
    };
    console.log('leaderboard module loaded!');
})();